import { Component, Inject, InjectionToken } from '@angular/core';
import { MessageRef } from './message-ref';

export const MA_MESSAGE_TYPE: InjectionToken<any> = new InjectionToken('MaMessageType');
export const MA_MESSAGE_TEXT: InjectionToken<any> = new InjectionToken('MaMessageText');
export enum MessageType { Error }

@Component({
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent {
  MessageType = MessageType;

  constructor(public messageRef: MessageRef,
    @Inject(MA_MESSAGE_TYPE) public type: MessageType,
    @Inject(MA_MESSAGE_TEXT) public text: string) {
  }

  hide() {
    this.messageRef.hide();
  }
}
