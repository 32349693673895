export interface IQuery {
    queryId: string;
    formQueryName: string;
    queryCategoryId: string;
    updateDate: Date;
    closeDate: Date;
    creationDate: Date;
    addressed: string;
    isAuto: boolean;
    queryTypeId: string;
    closedBy: string;
    closedById: string;
    closureTypeId: string;
    formTemplateItemId: string;
    roundNumber: number;
    queryThreads: IQueryThread[];
    reviewRoundQueryThreads: IQueryReviewRound[];
    formQueryTypeId: string;
    formDiscrepancyTypeId: string;
    closedByFirstName: string;
    closedByLastName: string;
    isResolved: boolean;
    isModified: boolean;
    queryIndicators: IQueryIndicator[];
}

export interface IQueryThread {
    queryThreadId?: string;
    queryId: string;
    personId: string;
    text: string;
    timestamp: Date;
    initial: boolean;
    edited: boolean;
    extendedText: string;
    manualText: string,
    autoText: string,
    roundNumber: number;
    personFirstName: string;
    personLastName: string;
    isClosingQueryThread: boolean;
    isEditMode: boolean;
    isAuto: boolean;
    isPersonReviewer: boolean;
    isNeedToUpdate: boolean;
    isDeleted: boolean;
    isAcknowledged: boolean;
}

export interface IQueryIndicator {
    itemName: string;
    sourceQuery: IQuery;
}

export class QueryIndicator implements IQueryIndicator {
    constructor(public itemName: string, public sourceQuery: IQuery) {
    }
}

export interface IQueryReviewRound {
    round: number;
    root: IQueryThread;
    replies: IQueryThread[];
    queryId: string;
}

export interface IRoundQueries {
    round: number;
    queries: IQuery[];
}

export enum FormQueryType {
    RaterComment = '24865443-38be-4f97-b8d4-63128695b31e',
    ReviewerComment = '0d279dad-50ae-4a27-a541-7cbed2fbd16f',
    Discrepancy = '4a5a3c66-7c52-47c5-9683-636b3360d6da',
}

export enum DiscrepancyType {
    FsScore = '07df7b76-1dfa-40ef-8cb2-1e39f00617dd',
    EdssStep = '28910ed1-b1e5-4f19-90c5-5897a68df4a6',
    CrossCheck = '46a096ad-65d3-46e6-8ca3-dc3291542d17',
    SubScores = 'e0640a37-fcae-437b-affc-d4f7c994d42b',
    PtMarkers = 'b2da6864-86e0-4c2e-a86c-7b3c3135b15f'
}

export enum QueryClosureType {
    ManuallyClosed = "5AFAEC8E-7C32-4008-9762-48C04D73B8C0",
    Accepted = "7AD2A792-D099-4DE8-8368-68C96094648B",
    Autoclosed = "4C652D97-AB4A-4897-85F1-3757D1E59272",
}

export enum MarkerType {
    Discrepancy = 0,
    Comment = 1,
    ReviewerComment = 2
}

export enum QueryType {
    ExternalReview = '55C0D534-4805-48A1-9278-2CFEC9427D52'
}