<form [formGroup]="queryThreadForm">
    <textarea [ngClass]="{'invalid': isTextBoxStateInvalid}"
        rows="1"
        cols="50"
        placeholder="Your comment..."
        maxlength="1000"
        formControlName="text">
    </textarea>
    <div class="comment-checkbox-row" *ngIf="canAcknowledgeField">
        <span class="or">or</span>
        <label>
            <span class="fake-choice choice-large">
                <input type="checkbox"
                       formControlName="isAcknowledged"
                       [ngClass]="{'invalid': isCheckBoxStateInvalid}">
                <span></span>
            </span>
            <span>I acknowledge</span>
        </label>
    </div>
</form>