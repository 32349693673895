import { Injectable, Injector } from '@angular/core';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ExtendableInjector } from './extendable-injector';
import { MA_MESSAGE_TYPE, MessageType, MA_MESSAGE_TEXT, MessageComponent } from './components/message/message.component';
import { MessageRef } from './components/message/message-ref';

@Injectable({
    providedIn: 'root'
})
export class MaMessagesService {
    private config: OverlayConfig = new OverlayConfig({
        positionStrategy: this.overlay.position().global().top('0').width('100vw'),
        hasBackdrop: false
    });

    constructor(private overlay: Overlay, private injector: Injector) { }

    showError(text: string): void {
        const overlayRef = this.overlay.create(this.config);
        const messageRef = new MessageRef(overlayRef);

        const map = new WeakMap();
        map.set(MessageRef, messageRef);
        map.set(MA_MESSAGE_TYPE, MessageType.Error);
        map.set(MA_MESSAGE_TEXT, text);

        const injector = new ExtendableInjector(this.injector, map);

        const portal = new ComponentPortal(MessageComponent, null, injector);
        overlayRef.attach(portal);
    }
}
