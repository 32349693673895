import { LayoutComponent } from './layout/layout.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './layout/about/about.component';
import { AuthGuard } from './guards/auth.guard';
import { PendingChangesGuard } from './guards/pending-changes-guard.guard';
import { SelectModeComponent } from './select-mode/select-mode.component';
import { FormInfoResolver } from './services/form-info.resolver';
import { ErrorPageComponent } from './shared/components/error-page/error-page.component';
import { AuthActivityGuard } from './guards/auth.activity.guard';

const routes: Routes = [
    {
        path:"about",
        component: AboutComponent
    },
    {
        path: ":formId",
        component: SelectModeComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "viewer/:formId",
        component: LayoutComponent,
        canActivate: [AuthActivityGuard],
        canDeactivate: [PendingChangesGuard],
        resolve: {
            formInfo: FormInfoResolver
        }
    },
    {
      path: 'error/:statusCode',
      canActivate: [AuthGuard],
      component: ErrorPageComponent
    },
    {
        path: '**',
        redirectTo: 'error/404'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { enableTracing: true })]
})
export class AppRoutingModule { }