import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { Overlay, OverlayRef, OverlayConfig } from '@angular/cdk/overlay';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { Subject, Observable } from 'rxjs';
import { first } from 'rxjs/operators';

export interface SpinnerRef {
    hide(): void;
}

class InternalSpinnerRef implements SpinnerRef {
    private counter = 1;
    private _afterDisposed: Subject<void> = new Subject();

    constructor(private overlayRef: OverlayRef) { }

    hide() {
        this.counter--;
        if (this.counter === 0) {
            this.overlayRef.dispose();
            this._afterDisposed.next();
        }
    }

    add() {
        this.counter++;
    }

    afterDisposed(): Observable<void> {
        return this._afterDisposed.asObservable();
    }
}

@Injectable({
    providedIn: 'root'
})
export class MaSpinnerService {
    private spinnerRef: InternalSpinnerRef = null;
    private config: OverlayConfig = new OverlayConfig({
        scrollStrategy: this.overlay.scrollStrategies.block(),
        positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
        backdropClass: 'ma-spinner-backdrop',
        hasBackdrop: true
    });

    constructor(private overlay: Overlay) { }

    get current(): SpinnerRef {
        return this.spinnerRef;
    }

    show(): SpinnerRef {
        if (this.spinnerRef) {
            this.spinnerRef.add();
        } else {
            const overlayRef = this.overlay.create(this.config);
            const portal = new ComponentPortal(SpinnerComponent);
            overlayRef.attach(portal);

            this.spinnerRef = new InternalSpinnerRef(overlayRef);
            this.spinnerRef.afterDisposed().pipe(first()).subscribe(() => this.spinnerRef = null);
        }

        return this.spinnerRef;
    }
}
