import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Optional, Output } from '@angular/core';
import { SubmitFormSettings } from './submit-form-settings';
import { HttpClient } from '@angular/common/http';
import { SubmitDialogApiService } from 'src/app/services/submit-dialog-api.service';
import { catchError, filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Subject, throwError } from 'rxjs';
import { MaSpinnerService } from 'src/app/shared/ma-spinner.service';
import { CredentialsInfo } from 'src/app/models/credentials-info.model';
import { SubmitService } from 'src/app/services/submit.service';
import { MultipleActiveSessionService } from 'src/app/services/multiple-active-session.service';
import { DIALOG_DATA, MaCompositeWizardRef, MaDialogRef } from 'ma-dialogs';

@Component({
    selector: 'submit-form',
    templateUrl: './submit-form.component.html',
    styleUrls: ['./submit-form.component.scss']
})
export class SubmitFormComponent implements OnInit, OnDestroy {
    @Optional() @Input() formId: string;
    @Optional() @Input() credentials: CredentialsInfo;

    @Optional() @Output() submitBtnVisibilityChange: EventEmitter<boolean> = new EventEmitter(true);
    @Optional() @Output() submitBtnStateChange: EventEmitter<boolean> = new EventEmitter(false);
    @Optional() @Output() footerVisibilityChange: EventEmitter<boolean> = new EventEmitter(false);

    public userName: string = '';
    public password: string = '';
    public credentialsInvalid: boolean = false;
    public phaseCssClass: string = '';
    public isPasswordShown: boolean = false;
    public isSubmitFormSuccess: boolean = false;
    public isSubmitEditingDialog: boolean = true;
    public submitResult: Object;
    public noFooter = true;

    private destroy$: Subject<void> = new Subject();

    public submitImageSuccessful: HTMLImageElement;
    public submitImageFailed: HTMLImageElement;
    public imageSuccessfulPath: string = '../../../assets/images/icon-done-inverted.svg';
    public imageFailedPath: string = '../../../assets/images/icon-cross-inverted.svg';

    constructor(
        protected http: HttpClient,
        protected submitDialogApiService: SubmitDialogApiService,
        private spinnerService: MaSpinnerService,
        private submitService: SubmitService,
        private multiActiveSessionService: MultipleActiveSessionService,
        @Optional() private wizardRef: MaCompositeWizardRef,
        @Optional() private dialogRefInjected?: MaDialogRef,
        @Optional() @Inject(DIALOG_DATA) public settingsInjected?: SubmitFormSettings,
    ) { }

    ngOnInit() {
        if (this.dialogRefInjected) {
            this.dialogRefInjected.overrideClose(() => this.closeDialog());
            this.multiActiveSessionService.multipleSessionDetected$
                .pipe(takeUntil(this.destroy$))
                .subscribe(() => {
                    if (this.spinnerService.current) {
                        this.spinnerService.current.hide();
                    }
                    this.dialogRefInjected.close(false);
                });
        }

        if (this.wizardRef) {
            this.wizardRef.overrideYes(() => {
                this.formSubmitHandler();
            });
            this.wizardRef.overrideClose(() => this.closeDialog());
        }
        this.submitImageSuccessful = this.preLoadImage(this.imageSuccessfulPath);
        this.submitImageFailed = this.preLoadImage(this.imageFailedPath);

        this.noFooter = this.settingsInjected ? this.settingsInjected.noFooter : this.noFooter;

        if (this.submitBtnVisibilityChange && this.submitBtnStateChange) {
            this.submitBtnVisibilityChange.next(true);
            this.submitBtnStateChange.next(!this.submitIsLocked());
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public formSubmitHandler() {
        this.submitAssessment(this.userName, this.password);
    }

    public switchPasswordVisibility() {
        this.isPasswordShown = !this.isPasswordShown;
    }

    public closeDialog() {
        this.dialogRefInjected ?
             this.dialogRefInjected.close(!this.isSubmitEditingDialog) :
             this.wizardRef.close(!this.isSubmitEditingDialog);
    }

    public fieldValueChange() {
        this.credentialsInvalid = false;
        if(this.submitBtnStateChange) {
            this.submitBtnStateChange.next(!this.submitIsLocked());
        }
    }

    public submitIsLocked(): boolean {
        return this.credentialsInvalid || !this.userName.length || !this.password.length;
    }

    public hasFooter(): boolean {
        return !this.noFooter;
    }

    private submitAssessment(userName: string, password: string) {
        const spinner = this.spinnerService.show();
        this.submitDialogApiService.validateCredentials(userName, password)
            .pipe(
                tap(isCredentialsValid => {
                    this.credentialsInvalid = !isCredentialsValid;
                    if (this.credentialsInvalid) {
                        spinner.hide();
                    }
                }),
                filter(isCredentialsValid => isCredentialsValid),
                take(1),
                switchMap(_ => {
                    this.submitService.needsPrepareSubmit$.next();
                    return this.submitService.assessmentReadyToSubmit$;
                }),
                takeUntil(this.multiActiveSessionService.multipleSessionDetected$),
                switchMap(_ => this.submitDialogApiService.submit(this.formId ?? this.settingsInjected.formId)),
                takeUntil(this.multiActiveSessionService.multipleSessionDetected$),
                catchError(err => {
                    this.submitService.setSubmitResult(true);
                    this.isSubmitFormSuccess = false;
                    this.isSubmitEditingDialog = false;
                    this.footerVisibilityChange.next(false);
                    spinner.hide();
                    return throwError(err);
                }),
                takeUntil(this.destroy$))
            .subscribe(submitResult => {
                this.submitService.setSubmitResult(true);
                this.isSubmitFormSuccess = submitResult;
                this.isSubmitEditingDialog = false;
                this.footerVisibilityChange.next(false);
                spinner.hide();
            });
    }

    private preLoadImage(source: string) {
        const img = new Image();
        img.src = source;
        return img;
    }
}
