<ng-container *ngIf="isSubmitEditingDialog; else submittedMessageBlock">
    <div class="fields-holder">
        <p>I confirm that I provided the information from this session submitted under my electronic signature.</p>
        <div class="error-notification" *ngIf="credentialsInvalid">
            Incorrect username or password. Try again.
        </div>
        <div class="field-row">
            <label class="input-field-box">
                <span class="label">Username</span>
                <input type="text"
                    title="Username"
                    placeholder="Enter your username"
                    #box
                    (keyup)="fieldValueChange()"
                    [(ngModel)]="userName"
                    [ngClass]="{'invalid': credentialsInvalid}">
            </label>
        </div>
        <div class="field-row">
            <label class="input-field-box">
                <span class="label">Password</span>
                <span class="show-password" role="button" *ngIf="!!password.length" (click)="switchPasswordVisibility()">
                    <i *ngIf="!isPasswordShown" class="icon-eye"></i>
                    <i *ngIf="isPasswordShown" class="icon-eye-on"></i>
                </span>
                <input type="{{ isPasswordShown ? 'text' : 'password' }}"
                    title="Password"
                    placeholder="Enter your password"
                    #box
                    (keyup)="fieldValueChange()"
                    [(ngModel)]="password"
                    [ngClass]="{'invalid': credentialsInvalid}">
            </label>
        </div>
    </div>
    <div *ngIf="hasFooter()" class="dialog-footer">
        <button type="button" class="btn btn-primary" (click)="closeDialog()">Cancel</button>
        <button type="button" class="btn btn-success" (click)="formSubmitHandler()" [disabled]="submitIsLocked()">Submit</button>
    </div>
</ng-container>

<ng-template #submittedMessageBlock>
    <div class="submit-result-box">
        <ng-container *ngIf="isSubmitFormSuccess; else submittErrorBlock">
            <div class="image-holder">
                <img [src]="submitImageSuccessful.src" alt="Submit successful">
            </div>
            <p>The assessment was submitted</p>
        </ng-container>
        <ng-template #submittErrorBlock>
            <div class="image-holder error-notice">
                <img [src]="submitImageFailed.src" alt="Submit failed">
            </div>
            <p>Something went wrong.<br>Close this window and submit the assessment again</p>
        </ng-template>
    </div>
</ng-template>