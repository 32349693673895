import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DateTimeService } from '../shared/date-time.service';

@Injectable()
export class JsonIsoDateParseInterceptor implements HttpInterceptor {
    constructor(private dateTimeService: DateTimeService) {
    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse){
                    this.convertDatesRecursive(event.body);
                }
                return event;
            }));
    }

    private convertDatesRecursive(instance: any): void {
        if (instance === null || instance === undefined || typeof instance !== 'object') {
              return;
        }

        for (const name of Object.keys(instance)) {
            const value = instance[name];
            if (typeof value === 'object') {
                this.convertDatesRecursive(value);
            } else if (this.dateTimeService.isIsoDate(value)) {
                instance[name] = this.dateTimeService.convertToDate(value);
            }
        }
    }
}
