import { BaseApiService } from './base-api.service';
import { HttpClient } from '@angular/common/http';
import { IFormInfo } from '../models/form-info.model';
import { Injectable, Inject } from '@angular/core';
import { MaMessagesService } from '../shared/ma-messages.service';
import { Observable, of } from 'rxjs';
import { CookieService } from './cookie.service';
import { IFormDetails } from '../models/form-details.model';
import { IEnvironmentSettings } from '../shared/environment-settings.interface';
import { UserActivityService } from './user-activity.service';

@Injectable({ providedIn: 'root' })
export class FormViewerApiService extends BaseApiService {
    constructor(
        @Inject('ENV_SETTINGS') environmentSettings: IEnvironmentSettings,
        protected http: HttpClient,
        protected cookieSvc: CookieService,
        messagesSvc: MaMessagesService,
        userActivityService: UserActivityService) {
        super(
            http,
            environmentSettings,
            cookieSvc,
            messagesSvc,
            userActivityService);
    }

    getFormInfo(formId: string): Observable<IFormInfo> {
         const url = `${this.environmentSettings.raterApi}${this.reviewApiUrl}/${formId}`;
         return this.http
              .get<IFormInfo>(url, { headers: this.noCacheHeader })
              .pipe(this.showError('Failed to get form info', of(null)));
    }

    saveReview(formId: any) {
        return this.http
            .post(`${this.environmentSettings.raterApi}${this.reviewApiUrl}/${formId}`, null)
            .pipe(this.showError('Failed to save Review', of(null)));
    }

    getFormDetails(formId: string): Observable<IFormDetails> {
        const url = `${this.environmentSettings.raterApi}${this.reviewApiUrl}/details/${formId}`;
        return this.http
            .get<IFormDetails>(url, { headers: this.noCacheHeader })
            .pipe(this.showError('Failed to get form details', of(null)));
    }
}
