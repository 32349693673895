import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IQuery } from '../models/query.interface';

@Injectable({ providedIn: 'root' })
export class ChangesDetectorService {
    private queries: IQuery[] = [];
    private componentModifiedEvent = new BehaviorSubject<boolean>(false);

    public emitModifiedEvent(query: IQuery) {
        const foundQuery = this.queries.find(q => q === query);
        if (!foundQuery) {
            this.queries.push(query);
        }

        this.componentModifiedEvent.next(this.queries.some(q => q.isModified));
    }

    public pendingChangesEventListner() {
        return this.componentModifiedEvent.asObservable();
    }

    destroy() {
        this.componentModifiedEvent.complete();
    }
}
