import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MultipleActiveSessionService } from './multiple-active-session.service';

@Injectable()
export class MultipleActiveSessionInterceptor implements HttpInterceptor {
    constructor(private multipleActiveSessionService: MultipleActiveSessionService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        req = req.clone({
            withCredentials: false,
            setHeaders: {
                'last-pull-date': this.multipleActiveSessionService.lastPullTime
            }
        });

        return next.handle(req);
    }
}
