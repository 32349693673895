import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SubjectSubscriber } from 'rxjs/internal/Subject';
import { filter, first, tap } from 'rxjs/operators';
import { IFormDetails, reviewStatus, subjectVisitStatus } from '../models/form-details.model';
import { IToken } from '../models/token.interface';
import { AssessmentApiService } from '../services/assessment-api.service';
import { AuthService } from '../services/auth.service';
import { FormViewerApiService } from '../services/form-viewer-api.service';
import { UserActivityService } from '../services/user-activity.service';
import { MaSpinnerService } from '../shared/ma-spinner.service';

@Component({
    selector: 'select-mode',
    templateUrl: './select-mode.component.html',
    styleUrls: ['./select-mode.component.scss']
})
export class SelectModeComponent implements OnInit {
    public formId: string;
    public informationMessage: string;

    @Input() formDetails: IFormDetails;

    constructor(
        private route: ActivatedRoute,
        private formViewerApiService: FormViewerApiService,
        private assessmentApiService: AssessmentApiService,
        private spinnerService: MaSpinnerService,
        private authService: AuthService,
        private userActivityService: UserActivityService) { }

    ngOnInit() {
        const spinner = this.spinnerService.show();

        this.route.params.subscribe(params => {
            this.formId = params.formId;
        });

        this.formViewerApiService.getFormDetails(this.formId)
            .pipe(
                first(),
                tap(_ => spinner.hide()),
                filter(detailsResponse => !!detailsResponse))
            .subscribe(detailsResponse => {
                this.formDetails = detailsResponse;
                this.informationMessage = this.getInformationMessage();
            }, error => {
                spinner.hide();
                throw new Error(error);
            }, () => spinner.hide());
    }

    public viewReview() {
        this.userActivityService.addAssessmentActivity(
            this.formId,
            this.authService.getAuthorizationTokenValues());

        this.formViewerApiService.navigateTo(`${location.origin}/#/viewer/${this.formId}`);
    }

    public editAssessment(): void {
        const spinner = this.spinnerService.show();
        this.assessmentApiService
            .editAssessment(this.formId)
            .subscribe(
                success => {
                    if (success) {
                        this.viewReview();
                    }
                },
                error => {
                    spinner.hide();
                    throw new Error(error);
                },
                () => spinner.hide());
    }

    public canUserEditForm(): boolean {
        return this.formDetails.canEdit && this.isUserQualified();
    }

    public isEditingDisabled(): boolean {
        return !this.isUserQualified()
            || this.isSubjectVisitInPorgress()
            || this.isDataLocked()
            || !this.formDetails.isRaterAssignedToReview;
    }

    public canOpenEditMode(): boolean {
        return this.formDetails.canEdit
            && !this.formDetails.canContinueAssessment
            && !this.formDetails.isSubmitted
            && this.isReviewStatusAwaitingRater();
    }

    public isFormLocked(): boolean {
        return this.formDetails.isLocked;
    }

    public isUserQualified(): boolean {
        return this.formDetails.canConductAssessment;
    }

    public isReviewStatusAwaitingRater(): boolean {
        return (this.formDetails.reviewStatus === reviewStatus.AwaitingRater || this.formDetails.reviewStatus === null)
            && !this.formDetails.isReviewInProcess;
    }

    public isSubjectVisitInPorgress(): boolean{
        return this.formDetails.subjectVisitStatus === subjectVisitStatus.InProgress;
    }

    public isDataLocked(): boolean {
        return this.formDetails.isDataLocked;
    }

    private getInformationMessage(): string {
        if (this.isDataLocked()) {
            return 'This assessment has been locked. No changes are allowed.';
        }

        if (this.isSubjectVisitInPorgress()) {
            return 'The visit needs to be completed before this assessment can be edited.';
        }

        if (this.canOpenEditMode()) {
            if (this.isFormLocked() && this.isUserQualified()) {
                return 'This assessment is currently edited by the rater.';
            }

            if (!this.isUserQualified()) {
                return 'You cannot edit this assessment. Please, contact the support team.';
            }

            if (!this.formDetails.isRaterAssignedToReview) {
                return 'Review is not assigned to you.';
            }

            if (this.isUserQualified() && this.formDetails.isRaterAssignedToReview) {
                return 'By clicking <b>Edit assessment</b> this assessment will be automatically assigned to you.';
            }
        }

        if (this.formDetails.canContinueAssessment) {
            return 'This assessment is assigned to you.';
        }

        return '';
    }
}