import { Injectable } from '@angular/core';
import { MaDialogRef, MaDialogsService } from 'ma-dialogs';
import { ConfirmationSettings } from '../shared/components/confirmation/confirmation-settings';
import { ConfirmationComponent } from '../shared/components/confirmation/confirmation.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private maDialogsService: MaDialogsService) {
  }

    public openConfirmPendingChangesDialog(): MaDialogRef {
        const settings: Partial<ConfirmationSettings> = {
          title: 'Confirm',
          yesButtonText: 'Leave',
          cancelButtonText: 'Cancel',
          text: 'There are unsaved changes. Are you sure you want to leave without saving?',
          width: '455px',
        };
        
        return this.maDialogsService.openDialog(ConfirmationComponent, settings);
    }

    public openMultipleSessionDetectedDialog(): MaDialogRef {
        const settings: Partial<ConfirmationSettings> = {
          title: 'Page was updated',
          hasCancelButton: false,
          width: '455px',
          text: `<p>Page content was updated in the other tab. You can close this window and copy your response or reload this page now.</p><p>After reloading the current changes will not be saved.</p>`,
          yesButtonText: 'Reload'
        };

        return this.maDialogsService.openDialog(ConfirmationComponent, settings);
    }
}
