import { Injectable } from '@angular/core';
import { IQueryThread } from '../query.interface';
import { IToken } from '../token.interface';
import { BaseFormQueryStateService } from './base-form-query-state-service';

@Injectable({
  providedIn: 'root'
})
export class CombinedRepliesFormQueryStateService extends BaseFormQueryStateService {

  public canBeClosed = false;
  public get queryType() {
    return '';
  };

  public saveQueryThreadChanges(token: IToken, queryThread: IQueryThread, text: string, isClose: boolean): void {
      queryThread.personId = token.personId;
      queryThread.personFirstName = token.firstname;
      queryThread.personLastName = token.lastname;
      queryThread.extendedText = `${queryThread.autoText}\n\n${text}`;
      queryThread.manualText = text;
      queryThread.text = queryThread.extendedText;
      queryThread.edited = true;
      this.queryStateUpdated.emit(this.query);
  }

  public addNewQueryThread(token: IToken, round: number, text: string, isClose: boolean): IQueryThread {
      const currentReviewRound = this.getReviewRoundQueryThread(round);
      const lastReply = currentReviewRound.replies[currentReviewRound.replies.length - 1];
      let ratersReply;

      if (!lastReply || lastReply.isPersonReviewer) {
        ratersReply = this.getNewQueryThread(token, round, text, false, false);
        ratersReply.autoText = 'The rater disagrees with the recommendation.';
        this.addQueryThreadToReview(ratersReply);
      }

      const autoQueryThread = ratersReply ?? lastReply;
      autoQueryThread.extendedText = `${autoQueryThread.autoText}\n\n${text}`;
      autoQueryThread.text = autoQueryThread.extendedText;
      autoQueryThread.manualText = text;
      this.query.isResolved = true;
      autoQueryThread.isAuto = false;
      autoQueryThread.edited = true;
      this.queryStateUpdated.emit(this.query);
      return autoQueryThread;
  }
}

