import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { WebBundleMode, WebBundleInputCommands } from './web-bundle-input-commands';
const FormViewSender = "FormView";

export class WebBundleOutputCommands extends WebBundleInputCommands {
    private cmd$ = new BehaviorSubject<IWebBundleOutputCommand>(emptyCmd);
    currentPageNumber = 0;
    pagesCount = 0;
    mode = WebBundleMode.Init;
    isFormLocked = false;
    formHeight = 0;
    canSubmitWithErrors$ = new Subject<boolean>();
    formErrorCount$ = new Subject<number>();

    get outCmd$(): Observable<IWebBundleOutputCommand> {
        return this.cmd$.asObservable();
    }

    get getCanSubmitWithErrors$(): Observable<Boolean> {
        return this.canSubmitWithErrors$.asObservable();
    }

    get getFormErrorCount$(): Observable<number> {
        return this.formErrorCount$.asObservable();
    }

    constructor() {
        super();
        window.onmessage = this.handleWebBundleOutputCommand.bind(this);
    }

    private canBeHandled(ev: MessageEvent) {
        return ev && ev.data && ev.data.sender === FormViewSender && ev.data.id;
    }

    private handleWebBundleOutputCommand(ev: MessageEvent) {
        if (this.canBeHandled(ev)) {
            const cmd = { cmdId: ev.data.id, data: ev.data.value };
            this.proxyWebBundleOutputCommand(cmd);
            this.cmd$.next(cmd);
        }
    }

    private proxyWebBundleOutputCommand({ cmdId, data }: IWebBundleOutputCommand) {
        switch (cmdId) {
            case WebBundleOutputCommandId.PageLoaded: this.pageLoaded(Number(data)); break;
            case WebBundleOutputCommandId.PageCount: this.setPagesCount(Number(data)); break;
            case WebBundleOutputCommandId.ModeChanged: this.modeChanged(data); break;
            case WebBundleOutputCommandId.HeightChanged: this.heightChanged(Number(data)); break;
            case WebBundleOutputCommandId.NeedRescale: this.needRescale(); break;
            case WebBundleOutputCommandId.FieldValueChanged: this.fieldValueChanged(data); break;
            case WebBundleOutputCommandId.FormState: this.handleNewFormState(data); break;
            case WebBundleOutputCommandId.NavSectionTexts: this.setNavSectionsTexts(data); break;
            case WebBundleOutputCommandId.NavSectionState: this.setNavSectionsState(data); break;
            case WebBundleOutputCommandId.FieldValue: this.fieldValue(data); break;
            case WebBundleOutputCommandId.CanSubmitwithErrors: this.emitCanSubmitWithErrors(data); break;
            case WebBundleOutputCommandId.FormErrorCount: this.emitFormErrorCount(Number(data)); break;
            case WebBundleOutputCommandId.FormStateLoaded: this.formStateLoaded(data); break;
        }
    }

    protected fieldValue(data: any) {
    }

    protected formStateLoaded(data: any) {
    }

    protected pageLoaded(value: number) {
        this.currentPageNumber = value;
    }

    protected emitCanSubmitWithErrors(value) {
        this.canSubmitWithErrors$.next(value);
    }

    protected emitFormErrorCount(value) {
        this.formErrorCount$.next(value);
    }

    protected setPagesCount(value: number) {
        this.pagesCount = value;
    }

    protected modeChanged(value: string) {
        switch (value.toLocaleLowerCase()) {
            case WebBundleMode.Edit: this.mode = WebBundleMode.Edit; break;
            case WebBundleMode.Review: this.mode = WebBundleMode.Review; break;
            default: this.mode = WebBundleMode.Locked; break;
        }
        this.isFormLocked = this.mode === WebBundleMode.Locked;
    }

    protected heightChanged(value: number) {
        this.formHeight = value;
    }

    protected needRescale() {
    }

    protected fieldValueChanged(data: string) {
        if (this.currentPageNumber > 1) {
            this.getCurrentPageErrorCount();
        }
    }

    protected handleNewFormState(state) { }
    protected setNavSectionsTexts(sectionTexts: ISectionText[]) { }
    protected setNavSectionsState(sectionState: ISectionState[]) { }

    destroy() {
        this.cmd$.complete();
    }
}

export enum WebBundleOutputCommandId {
    None = "none",
    PageLoaded = "page-loaded",
    PageCount = "page-count",
    ModeChanged = "mode-changed",
    HeightChanged = "height-changed",
    FormState = "form-state",
    NeedRescale = "need-rescale",
    FormErrorCount = "error-count",
    CurrentPageErrorCount = "current-page-error-count",
    FieldValueChanged = "field-value-changed",
    NavSectionTexts = "nav-section-texts",
    NavSectionState = "nav-section-state",
    FieldValue = "field-value",
    ValidationStatus = "validation-status",
    CanSubmitwithErrors = 'can-submit-with-errors',
    FormStateLoaded = 'form-state-loaded'
}

const emptyCmd: IWebBundleOutputCommand = { cmdId: WebBundleOutputCommandId.None, data: null };
export interface IWebBundleOutputCommand { cmdId: WebBundleOutputCommandId; data: any; }
export interface ISectionText { id: string; page: number; text: string; }
export interface ISectionState { id: string; alert: boolean; }
