import { ISectionItem } from "./section-item.interface";

export interface ISectionItems {
    name: string;
    isExpanded?: boolean;
    sectionTypeId: string;
    rawScoreValue: string;
    rawScoreItemId: string;
    rawScoreName: string;
    convertedScoreItemId: string;
    recommendedScoreItemId: string;
    convertedScoreValue: string;
    recommendedScoreValue: string;
    updatedRawScoreValue: string;
    updatedConvertedScoreValue: string;
    recommendedScoreName: string;
    sectionItems: ISectionItem[];
    sectionStartPage?: number;
    sectionNumber?: number;
    updatedScoreCss: string;
    reviewerRawScoreValue: string;
}

export enum ScoreCssClass {
    Primary = 'primary',
    Success = 'success',
    Secondary = 'secondary',
    Invisible = 'invisible',
}