import { IQueryReviewRound, IQueryThread } from '../query.interface';
import { BaseViewFormQueryStateService } from './base-view-form-query-state-service';

export abstract class AcknowledgeViewQueryStateService extends BaseViewFormQueryStateService {
    public canAcknowledge(queryThread: IQueryThread, currentRound: number): boolean {
        const reviewQueryThread = this.formStateService.getReviewRoundQueryThread(currentRound);
        const anyOtherAcknowledgeQueryThread = reviewQueryThread.replies.every(qt => !qt.isAcknowledged && qt !== queryThread);

        return !!queryThread
            ? queryThread.isAcknowledged && !anyOtherAcknowledgeQueryThread
            : anyOtherAcknowledgeQueryThread;
    }

    public canAddReply(queryReviewRound: IQueryReviewRound, currentRound: number): boolean {
        if (this.isClosed(queryReviewRound) || queryReviewRound.round < currentRound) {
            return false;
        }
        const reviewQueryThread = this.formStateService.getReviewRoundQueryThread(currentRound);
        return reviewQueryThread && reviewQueryThread.replies.every(qt => !qt.isAcknowledged);
    }
}
