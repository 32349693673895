export interface IFormDetails {
    formId: string;
    study: string;
    subject: string;
    svid: string;
    site: string;
    country: string;
    visit: string;
    assessment: string;
    reviewStatus?: number;
    subjectVisitStatus: number;
    canEdit: boolean;
    canConductAssessment: boolean;
    canContinueAssessment: boolean;
    isLocked: boolean;
    round: number;
    isSubmitted: boolean;
    isReviewInProcess: boolean;
    isDataLocked: boolean;
    isRaterAssignedToReview: boolean;
}

export class FormDetails implements IFormDetails {
    constructor(like: IFormDetails) {
        this.formId = like.formId;
        this.study = like.study;
        this.subject = like.subject;
        this.svid = like.svid;
        this.site = like.site;
        this.country = like.country;
        this.visit = like.visit;
        this.subject = like.subject;
        this.assessment = like.assessment;
        this.reviewStatus = like.reviewStatus;
        this.subjectVisitStatus = like.subjectVisitStatus;
        this.canEdit = like.canEdit;
        this.canConductAssessment = like.canConductAssessment;
        this.canContinueAssessment = like.canContinueAssessment;
        this.isLocked = like.isLocked;
        this.round = like.round;
        this.isSubmitted = like.isSubmitted;
        this.isReviewInProcess = like.isReviewInProcess;
        this.isDataLocked = like.isDataLocked;
        this.isRaterAssignedToReview = like.isRaterAssignedToReview;
    }

    formId: string;
    study: string;
    subject: string;
    svid: string;
    site: string;
    country: string;
    visit: string;
    assessment: string;
    reviewStatus?: number;
    subjectVisitStatus: number;
    isLocked: boolean;
    canEdit: boolean;
    canConductAssessment: boolean;
    canContinueAssessment: boolean;
    round: number;
    isSubmitted: boolean;
    isReviewInProcess: boolean;
    isDataLocked: boolean;
    isRaterAssignedToReview: boolean;
}

export enum reviewStatus {
    Pending = 0,
    InProgress = 1,
    AwaitingRater = 2,
    Complete = 3,
}

export enum subjectVisitStatus{
    None = 0,
    Skipped = 1,
    Pending = 2,
    Requested = 3,
    Cancelled = 4,
    Scheduled = 5,
    Initiated = 6,
    InProgress = 7,
    Complete = 8,
    Editing = 9
}