import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IEnvironmentSettings } from '../shared/environment-settings.interface';
import { MaMessagesService } from '../shared/ma-messages.service';
import { BaseApiService } from './base-api.service';
import { CookieService } from './cookie.service';
import { UserActivityService } from './user-activity.service';

@Injectable({ providedIn: 'root' })
export class AssessmentApiService extends BaseApiService {
    constructor(
        @Inject('ENV_SETTINGS') environmentSettings: IEnvironmentSettings,
        protected http: HttpClient,
        protected cookieSvc: CookieService,
        messagesSvc: MaMessagesService,
        userActivityService: UserActivityService) {
        super(
            http,
            environmentSettings,
            cookieSvc,
            messagesSvc,
            userActivityService);
    }

    editAssessment(reviewId: string): Observable<boolean> {
        const url = `${this.environmentSettings.raterApi}${this.assessmentApiUrl}/edit/${reviewId}`;
        return this.http.post<boolean>(url, null).pipe(this.showError('Failed to start assessment', of(null)));
    }
}
