import { Constants } from 'src/app/shared/constants';
import { FormQueryType, IQueryReviewRound, IQueryThread } from '../query.interface';
import { IToken } from '../token.interface';
import { BaseFormQueryStateService } from './base-form-query-state-service';

export class CommentFormQueryStateService extends BaseFormQueryStateService {
    public get queryType(): string {
        return FormQueryType.RaterComment;
    }

    protected groupQueryThreadsByRound(): void {
        const map = new Map<number, IQueryThread[]>();
        const sortedQueryThreads = this.query.queryThreads.sort((a, b) => a.roundNumber - b.roundNumber);

        sortedQueryThreads.forEach(item => {
            const key = item.roundNumber;
            if (map.has(key)) {
                map.get(key).push(item);
            } else {
                map.set(key, [item]);
            }
        });

        const roundNumbers: number[] = [...map.keys()];
        const maxRound = Math.max(...roundNumbers);

        roundNumbers.forEach(roundNumber => {
            let queryThreads = map.get(roundNumber);
            const prevRoundNumber = roundNumber - 1;
            if (map.has(prevRoundNumber)) {
                queryThreads = queryThreads.concat(map.get(prevRoundNumber));
            }

            map.set(
                roundNumber,
                queryThreads.sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime()));
        });

        if (!this.query.closeDate && maxRound < this.currentRoundNumber) {
            map.set(this.currentRoundNumber, map.get(maxRound));
        }

        this.query.reviewRoundQueryThreads = [];
        map.forEach((value, key) => {
            const rootThread = value.filter((queryThread) => queryThread.initial === true)[0];
            if (rootThread) {
                this.query.reviewRoundQueryThreads.push({
                    round: key,
                    root: rootThread,
                    replies: value.filter((queryThread, index) =>
                        index !== value.indexOf(rootThread)),
                    queryId: this.query.queryId
                });
            } else {
                this.query.reviewRoundQueryThreads.push({
                    round: key,
                    root: value[0],
                    replies: value.filter((queryThread, index) => index >= 1),
                    queryId: this.query.queryId
                });
            }
        });
    }

    public saveQueryThreadChanges(
        token: IToken,
        queryThread: IQueryThread,
        text: string,
        isAcknowledged: boolean): void {
        this.query.isResolved = true;
        queryThread.text = text;
        queryThread.extendedText = text;
        queryThread.edited = true;
        queryThread.timestamp = !!queryThread.timestamp
            ? queryThread.timestamp
            : this.dateTimeService.getServerCurrentDateUtc();
        this.queryStateUpdated.emit(this.query);
    }

    public deleteQueryThread(queryThread: IQueryThread): void {
        this.deleteQueryThreadFromReview(queryThread);

        if (this.query.isAuto) {
            this.query.isResolved = this.hasRaterComments(queryThread.roundNumber);
        }

        this.queryStateUpdated.emit(this.query);
    }

    public hasRaterComments(roundNumber: number): boolean {
        const reviewQueryThread = this.getReviewRoundQueryThread(roundNumber);
        return reviewQueryThread.replies.some(reply =>
            !reply.isPersonReviewer
            && reply.roundNumber === reviewQueryThread.round
            && reply.personId !== Constants.systemUserId);
    }
}
