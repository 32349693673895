import { BaseApiService } from './base-api.service';
import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { MaMessagesService } from '../shared/ma-messages.service';
import { Observable, of } from 'rxjs';
import { CookieService } from './cookie.service';
import { IEnvironmentSettings } from '../shared/environment-settings.interface';
import { UserActivityService } from './user-activity.service';

@Injectable({ providedIn: 'root' })
export class SubmitDialogApiService extends BaseApiService {

    constructor(
        @Inject('ENV_SETTINGS') environmentSettings: IEnvironmentSettings,
        protected http: HttpClient,
        protected cookieSvc: CookieService,
        messagesSvc: MaMessagesService,
        userActivityService: UserActivityService) {
        super(
            http,
            environmentSettings,
            cookieSvc,
            messagesSvc,
            userActivityService);
    }

    validateCredentials(userName: string, password: string): Observable<boolean> {
        const url = `${this.raterApiAuthorizationUrl}/validate-credentials/${document.location.host}`;
        return this.http.post<boolean>(url, { Username: userName, Password: password });
    }

    submit(formId: string): Observable<boolean> {
        const url = `${this.environmentSettings.raterApi}${this.assessmentApiUrl}/submit/${formId}`;
        return this.http.get<boolean>(url);
    }
}
