import { IFormQueryStateService } from 'src/app/interfaces/form-query-state/form-query-state-service.interface';
import { IViewFormQueryStateService } from 'src/app/interfaces/form-query-state/view-query-state-service.interface';
import { QueryThreadFormValue } from '../query-thread-form-value.model';
import { IQueryReviewRound, IQueryThread } from '../query.interface';

export abstract class BaseViewFormQueryStateService implements IViewFormQueryStateService {
    constructor(protected formStateService: IFormQueryStateService) {
    }

    public canAddReply(queryReviewRound: IQueryReviewRound, currentRound: number): boolean {
        return !this.isClosed(queryReviewRound) && queryReviewRound.round === currentRound;
    }

    protected isClosed(queryReviewRound: IQueryReviewRound): boolean {
        return this.formStateService.query.closeDate != null || this.formStateService.isClosed(queryReviewRound);
    }

    get markerElementCssClassName(): string {
        return 'marker-element';
    }

    get markerText(): string {
        return this.formStateService.queryType;
    }

    get notResolvedQueryCssClassName(): string {
        return 'danger';
    }

    canAcknowledge(queryThread: IQueryThread, currentRound: number): boolean {
        return false;
    }

    isEnabledSaveEditForm(queryThread: IQueryThread, queryThreadFormValue: QueryThreadFormValue): boolean {
        const isTextChanged = (!!queryThreadFormValue.text
            && !!queryThreadFormValue.text.trim()
            && queryThreadFormValue.text !== queryThread.text) ||
            (queryThreadFormValue.text !== queryThread.text
            && !queryThreadFormValue.text);

        const isAcknowledgedChanged = queryThreadFormValue.isAcknowledged !== null
            && queryThreadFormValue.isAcknowledged !== queryThread.isAcknowledged;

        const isAllFieldsEmpty = (!queryThreadFormValue.text || !queryThreadFormValue.text.trim())
            && !queryThreadFormValue.isAcknowledged;

        return (isTextChanged || isAcknowledgedChanged) && !isAllFieldsEmpty;
    }

    canManageQueryThread(queryThread: IQueryThread, currentRound: number): boolean {
        return !queryThread.isAuto
            && queryThread.roundNumber === currentRound;
    }

    public canDeleteQueryThread(queryThread: IQueryThread, currentRound: number): boolean {
        return this.canManageQueryThread(queryThread, currentRound);
    }

    public canDeleteRootQueryThread(queryThread: IQueryThread): boolean {
        return !queryThread.isEditMode;
    }

    public canManageRootQueryThread(queryReviewRound: IQueryReviewRound): boolean {
        return false;
    }

    public isEnabledSaveAddForm(queryThreadFormValue: QueryThreadFormValue): boolean {
        const isTextChanged = !!queryThreadFormValue
            && !!queryThreadFormValue.text
            && !!queryThreadFormValue.text.trim();

        const isAcknowledgedChanged = queryThreadFormValue
            && !!queryThreadFormValue.isAcknowledged
            && queryThreadFormValue.isAcknowledged !== !!this.formStateService.query.closeDate;

        return isTextChanged || isAcknowledgedChanged;
    }

    isQueryResolutionRequired(): boolean {
        const query = this.formStateService.query;
        return !query.isResolved && !query.closeDate;
    }

    public isReplyTextRequired(): boolean {
        return false;
    }

    public getDisplayText(queryThread: IQueryThread): string {
        const isRootReply = this.formStateService.query.reviewRoundQueryThreads
            .some(reviewRound => reviewRound.root === queryThread);

        if (isRootReply) {
            return queryThread.text;
        }

        return !!queryThread.isClosingQueryThread || !!queryThread.extendedText
            ? queryThread.extendedText
            : queryThread.text
    }

    public getEditableText(queryThread: IQueryThread): string {
        return this.getDisplayText(queryThread);
    }

    public getReadOnlyText(queryThread: IQueryThread): string {
        return '';
    }
}