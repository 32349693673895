import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'error-page',
    templateUrl: './error-page.component.html',
    styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent {
    statusCode: string;
    errorMessage: string;

    constructor(route: ActivatedRoute) {
        this.statusCode = route.snapshot.params.statusCode;
        this.setErrorMessage();
    }

    private setErrorMessage() {
        switch (this.statusCode) {
            case '403':
                this.errorMessage = "403. Access denied. You don’t have permission to access to the assessment";
                break;
            case '404':
                this.errorMessage = "404. Page is not found";
                break;
            default:
                this.errorMessage = this.statusCode;
        }
    }
}
