import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { CookieService } from '../services/cookie.service';
import { UserActivityService } from '../services/user-activity.service';
import { AuthGuard } from './auth.guard';

@Injectable({
    providedIn: 'root'
})
export class AuthActivityGuard extends AuthGuard {
    constructor(
        cookieSvc: CookieService,
        protected authService: AuthService,
        private userActivityService: UserActivityService) {
        super(cookieSvc, authService);
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const isUserAuthorized = super.canActivate(route, state);

        if (isUserAuthorized) {
            return this.userActivityService.checkAssessmentActivity(
                route.params.formId,
                this.authService.getAuthorizationTokenValues());
        }

        return false;
    }
}
