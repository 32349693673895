import { BrowserModule } from '@angular/platform-browser';
import { FormViewerComponent } from './form-viewer.component';
import { NavPanelComponent } from './nav-panel/nav-panel.component';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { TrustResourcePipe } from './viewer/trust-resource.pipe';
import { ViewerComponent } from './viewer/viewer.component';

@NgModule({
  declarations: [
    TrustResourcePipe,
    NavPanelComponent,
    ViewerComponent,
    FormViewerComponent
  ],
  imports: [
    RouterModule.forRoot([]),
    BrowserModule,
    SharedModule
  ],
  providers: [],
  exports: [FormViewerComponent]
})
export class FormViewerModule { }
