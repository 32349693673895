import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { QueryThreadFormValue } from 'src/app/models/query-thread-form-value.model';
import { IQueryThread } from 'src/app/models/query.interface';

@Component({
    selector: 'query-thread-form',
    templateUrl: './query-thread-form.component.html',
    styleUrls: ['./query-thread-form.component.scss']
})
export class QueryThreadFormComponent implements OnInit, OnDestroy {
    private textInitialValue: string;
    private checkboxInitialValue: boolean;

    @Input() queryThread: IQueryThread = null;
    @Input() canAcknowledgeField: boolean = true;
    @Input() isRequired: boolean = false;
    @Input() isTextBoxValueRequired: boolean;

    @Input() resetChanges: Observable<void>;

    @Input() set text(text: string) {
        this.textInitialValue = text;
        this.setCheckboxRestriction(!!text && !!text.trim().length);
        this.queryThreadForm.get('text').setValue(text);
    }

    @Input() set isAcknowledged(isAcknowledged: boolean) {
        this.checkboxInitialValue = isAcknowledged;
        this.queryThreadForm.get('isAcknowledged').setValue(isAcknowledged);
    }

    @Output() formChanged: EventEmitter<QueryThreadFormValue> = new EventEmitter<QueryThreadFormValue>();
    @Output() formSubmitted: EventEmitter<QueryThreadFormValue> = new EventEmitter<QueryThreadFormValue>();

    private destroy$: Subject<void> = new Subject();

    public queryThreadForm: FormGroup = this.fb.group({
        text: [null, [Validators.maxLength(1000)]],
        isAcknowledged: [false]
    });

    constructor(private fb: FormBuilder) {}

    ngOnInit(): void {
        if (this.canAcknowledgeField) {
            this.queryThreadForm.get('text').valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                const isTextNotEmpty = this.isTextNotEmpty();
                this.setCheckboxRestriction(isTextNotEmpty);
                const checkBoxControl = this.queryThreadForm.get('isAcknowledged');
                checkBoxControl.setValue(checkBoxControl.value || isTextNotEmpty);
            });
        }

        this.queryThreadForm.valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.emitValue(this.formChanged));

        this.resetChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.queryThreadForm.patchValue({
                    text: this.textInitialValue,
                    isAcknowledged: this.checkboxInitialValue
                });
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public hasValidationError(controlName: string, errorName: string): boolean {
        const control = this.queryThreadForm.get(controlName);
        return control.errors !== null && control.errors.hasOwnProperty(errorName);
    }

    public get isTextBoxStateInvalid() {
        if (!this.isRequired && !this.queryThread) {
            return false;
        }

        const text = this.queryThreadForm.get('text').value;

        if (this.isTextBoxValueRequired) {
            return !text;
        } else {
            const checked = this.queryThreadForm.get('isAcknowledged').value;
            return !(!!text && !!text.trim() || !!checked);
        }
    }

    public get isCheckBoxStateInvalid() {
        if (!this.isRequired && !this.queryThread) {
            return false;
        }

        const checked = this.queryThreadForm.get('isAcknowledged').value;
        return !checked;
    }

    public submit(): void {
        this.emitValue(this.formSubmitted);
        this.queryThreadForm.reset();
    }

    public isTextNotEmpty(): boolean {
        return this.queryThreadForm.get('text').value && !!this.queryThreadForm.get('text').value.length;
    }

    private emitValue(emitter: EventEmitter<QueryThreadFormValue>): void {
        emitter.emit({
            text: this.queryThreadForm.get('text').value?.trim() || '',
            isAcknowledged: this.queryThreadForm.get('isAcknowledged').value || false
        });
    }

    private setCheckboxRestriction(isDisabled: boolean): void {
        const checkBoxControl = this.queryThreadForm.get('isAcknowledged');
        const action = isDisabled ? 'disable' : 'enable';
        checkBoxControl[action]();
    }
}
