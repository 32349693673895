import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LayoutModule } from './layout/layout.module';
import { AppRoutingModule } from './app-routing.module';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AboutComponent } from './layout/about/about.component';
import { InterceptorModule } from './services/interceptor.module';
import { SharedModule } from './shared/shared.module';
import { SelectModeModule } from './select-mode/select-mode.module';
import { CustomRouteReuseStrategy } from './shared/custom-route-reuse-strategy';

@NgModule({
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    RouterModule,
    LayoutModule,
    InterceptorModule,
    SharedModule,
    SelectModeModule
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy }],
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    AboutComponent
  ],
})
export class AppModule { }
