import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { FormScoreValue } from 'src/app/models/form-score-value.model';
import { QueryMarkerData } from 'src/app/models/query-marker';
import { IQuery, IQueryReviewRound, IRoundQueries } from 'src/app/models/query.interface';
import {
    AUTO_STYLE,
    animate,
    state,
    style,
    transition,
    trigger
} from '@angular/animations';

@Component({
    selector: 'form-query-review-round',
    templateUrl: './form-query-review-round.component.html',
    styleUrls: ['./form-query-review-round.component.scss'],
    animations: [
        trigger('collapse', [
            state('false', style({
                height: AUTO_STYLE
            })),
            state('true', style({
                height: '0'
            })),
            transition('false => true', animate(300 + 'ms ease-in-out')),
            transition('true => false', animate(300 + 'ms ease-in-out'))
        ])
    ]
})
export class FormQueryReviewRoundComponent implements OnInit, OnDestroy {
    @Input() sectionQueriesByRound: IRoundQueries;
    @Input() isExpanded: boolean;
    @Input() currentRound: number;
    @Input() isViewMode: boolean;
    @Input() reviewerScoreValue: string;
    @Input() raterScoreValue: string;
    @Input() onFieldValueChanged: Observable<FormScoreValue>;
    @Input() onFieldValueLoaded: Observable<FormScoreValue>;
    @Input() changeableScoreName: string;
    @Input() resetChanges: Observable<void>;

    @Output() queryDeleted: EventEmitter<IQuery> = new EventEmitter<IQuery>();
    @Output() queryUpdated: EventEmitter<IQuery> = new EventEmitter<IQuery>();

    @Input() sectionUpdated$: Subject<void>;
    @Input() queryMarkersUpdated$: Subject<QueryMarkerData> = new Subject();
    queryMarkersData: QueryMarkerData;

    private destroy$: Subject<void> = new Subject();

    ngOnInit(): void {
        this.setMarkersData();
        this.queryMarkersUpdated$.next(this.queryMarkersData);

        this.sectionUpdated$.subscribe(_ => {
            this.setMarkersData();
            this.queryMarkersUpdated$.next(this.queryMarkersData);
        });
    }

    ngOnDestroy(): void {
        this.destroy$.complete();
        this.queryMarkersUpdated$.complete();
    }

    public getQueryReviewRounds(): IQueryReviewRound[] {
        return this.sectionQueriesByRound.queries
            .reduce((q, nq) => [ ...q, ...nq.reviewRoundQueryThreads ], [])
            .filter(rrqt => rrqt.round === this.sectionQueriesByRound.round);
    }

    public getQuery(queryId: string): IQuery {
        return this.sectionQueriesByRound.queries.find(q => q.queryId === queryId);
    }

    public toggleRound(): void {
        this.isExpanded = !this.isExpanded;
    }

    public isExpandedThread(queryReviewRound: IQueryReviewRound): boolean {
        return queryReviewRound.round === this.currentRound;
    }

    public onQueryDeleted(query: IQuery) {
        this.queryDeleted.next(query);
        this.queryMarkersUpdated$.next(this.queryMarkersData);
    }

    public updateQueries(query: IQuery): void {
        this.queryUpdated.next(query);
        this.queryMarkersUpdated$.next(this.queryMarkersData);
    }

    public getHeaderLabel(): string {
        return `Round ${this.sectionQueriesByRound.round}`;
    }

    public areQueriesInCurrentRound(): boolean {
        return this.sectionQueriesByRound.round === this.currentRound;
    }

    private setMarkersData(): void {
        const queries = this.getQueryReviewRounds().map(queryReviewRound => {
            const query = Object.assign({}, this.getQuery(queryReviewRound.queryId));
            query.isResolved = this.currentRound > this.sectionQueriesByRound.round || query.isResolved;
            return query;
        });

        this.queryMarkersData = {
            queries: queries,
            indicators: []
        };
    }
}
