import { IQueryReviewRound, IQueryThread } from '../query.interface';
import { BaseViewFormQueryStateService } from './base-view-form-query-state-service';
import { CommentFormQueryStateService } from './comment-form-query-state-service';

export class CommentViewFormQueryStateService extends BaseViewFormQueryStateService {
    get markerElementCssClassName(): string {
        return 'icon-comment';
    }

    get markerText(): string {
        return '';
    }

    canAddReply(queryReviewRound: IQueryReviewRound, currentRound: number): boolean {
        if (this.isClosed(queryReviewRound)) {
            return false;
        }
        const isCreatedInCurrentRound = queryReviewRound.round === currentRound && !queryReviewRound.replies.length;
        const commentStateService = this.formStateService as CommentFormQueryStateService;

        if (isCreatedInCurrentRound || commentStateService.hasRaterComments(queryReviewRound.round)) {
            return false;
        }
        const hasClosingQueryThreads = this.formStateService.query.queryThreads.some(qt => qt.isClosingQueryThread);
        if (hasClosingQueryThreads) {
            const closingQueryThreads = this.formStateService.query.queryThreads.filter(qt => qt.isClosingQueryThread);
            return closingQueryThreads.some(qt => qt.roundNumber === currentRound);
        }
        return this.formStateService.query.queryThreads.every(qt => !!qt.text);
    }

    public canManageRootQueryThread(queryReviewRound: IQueryReviewRound): boolean {
        const findElement = this.formStateService.getReviewRoundQueryThread(queryReviewRound.round);

        const lastReviewRoundIndex = this.formStateService.reviewRoundQueryThreads.length - 1;
        const currentReviewRoundIndex = this.formStateService.reviewRoundQueryThreads.indexOf(findElement);

        return lastReviewRoundIndex === currentReviewRoundIndex;
    }

    public getDisplayText(queryThread: IQueryThread): string {
        return queryThread.extendedText;
    }

    public canDeleteRootQueryThread(queryThread: IQueryThread): boolean {
        return !queryThread.isEditMode || !this.getDisplayText(queryThread);
    }
}
