import { CommonModule } from '@angular/common';
import { FormReviewerModule } from './form-reviewer/form-reviewer.module';
import { FormViewerModule } from './form-viewer/form-viewer.module';
import { NgModule } from '@angular/core';
import { LayoutComponent } from './layout.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        FormReviewerModule,
        FormViewerModule,
        SharedModule,
        FormsModule
    ],
    declarations: [
        LayoutComponent,
    ],
    exports: [
    ]
})
export class LayoutModule { }
