export class WebBundleInputCommands {

    getFieldValue(fieldId: string) {
        this.sendCommandToWebBundle(WebBundleInputCommandId.GetFieldValue, fieldId);
    }

    setFieldValue(fieldId: string, value: string) {
        this.sendCommandToWebBundle(WebBundleInputCommandId.SetFieldValue, JSON.stringify({ id: fieldId, value }));
    }

    getFormErrorCount() {
        this.sendCommandToWebBundle(WebBundleInputCommandId.GetFormErrorCount);
    }

    getCurrentPageErrorCount() {
        this.sendCommandToWebBundle(WebBundleInputCommandId.GetCurrentPageErrorCount);
    }

    getFormState() {
        this.sendCommandToWebBundle(WebBundleInputCommandId.GetFormState);
    }

    getValidationStatus() {
        this.sendCommandToWebBundle(WebBundleInputCommandId.GetValidationStatus);
    }

    setFormState(state: string) {
        this.sendCommandToWebBundle(WebBundleInputCommandId.SetFormState, state);
    }

    switchWebBundleToLockedMode() {
        this.sendCommandToWebBundle(WebBundleInputCommandId.SetMode, WebBundleMode.Locked);
    }

    switchWebBundleToEditMode() {
        this.sendCommandToWebBundle(WebBundleInputCommandId.SetMode, WebBundleMode.Edit);
    }

    goToPage(pageNumber: number) {
        this.sendCommandToWebBundle(WebBundleInputCommandId.GotoPage, pageNumber);
    }

    scaleToWidth(width: number) {
        this.sendCommandToWebBundle(WebBundleInputCommandId.ScaleToWidth, width);
    }

    turnOffOverrides() {
        this.sendCommandToWebBundle(WebBundleInputCommandId.TurnOffOverrides);
    }

    turnOffHandwriting() {
        this.sendCommandToWebBundle(WebBundleInputCommandId.TurnOffHandwriting);
    }

    turnOffPageOrSectionMenu() {
        this.sendCommandToWebBundle(WebBundleInputCommandId.TurnOffPageOrSectionMenu);
    }

    goToSection(sectionId: string) {
        this.sendCommandToWebBundle(WebBundleInputCommandId.GoToSection, sectionId);
    }

    getNavSectionState() {
        this.sendCommandToWebBundle(WebBundleInputCommandId.GetNavSectionState);
    }

    enableValidation() {
        this.sendCommandToWebBundle(WebBundleInputCommandId.EnableValidation);
    }

    getCanSubmitWithErrors() {
        this.sendCommandToWebBundle(WebBundleInputCommandId.GetCanSubmitWithErrors);
    }

    sendCommandToWebBundle(cmdId: WebBundleInputCommandId, data?: any) {
        const formFrame = document.getElementById("formview") as HTMLIFrameElement;
        if (formFrame) {
            formFrame.contentWindow.postMessage({ id: cmdId, value: data }, "*");
        }
    }
}

export enum WebBundleInputCommandId {
    GetFieldValue = "get-field-value",
    SetFieldValue = "set-field-value",
    GetFormErrorCount = "get-error-count",
    GetCurrentPageErrorCount = "get-current-page-error-count",
    GetFormState = "get-form-state",
    SetFormState = "set-form-state",
    SetMode = "set-mode",
    GotoPage = "goto-page",
    ScaleToWidth = "scale-to-width",
    TurnOffOverrides = "turn-off-overrides",
    TurnOffHandwriting = "turn-off-handwriting",
    TurnOffPageOrSectionMenu = "turn-off-menu",
    GoToSection = "goto-section",
    GetNavSectionState = "get-nav-section-state",
    EnableValidation = "enable-validation",
    GetValidationStatus = "get-validation-status",
    GetCanSubmitWithErrors = "get-can-submit-with-errors",

    TurnOffGuides = "turn-off-guides",
    ApplyFormOverride = "apply-form-override",
}

export enum WebBundleMode {
    Init = "init",
    Edit = "edit",
    View = "view",
    Locked = "locked",
    Review = "review"
}
