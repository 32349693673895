import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormReviewerComponent } from './form-reviewer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormSectionsComponent } from './form-sections/form-sections.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { QueryThreadFormComponent } from '../form-query/query-thread-form/query-thread-form.component';
import { FormQueryThreadTreeComponent } from '../form-query/form-query-thread-tree/form-query-thread-tree.component';
import { FormQueryReviewRoundComponent } from '../form-query/form-query-review-round/form-query-review-round.component';
import { FormQueryMarkersComponent } from '../form-query/form-query-markers/form-query-markers.component';

@NgModule({
  declarations: [
    FormReviewerComponent,
    FormSectionsComponent,
    QueryThreadFormComponent,
    FormQueryThreadTreeComponent,
    FormQueryReviewRoundComponent,
    FormQueryMarkersComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [],
  exports: [FormReviewerComponent, FormSectionsComponent]
})
export class FormReviewerModule { }
