import { Component, OnDestroy, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DiscrepancyType, FormQueryType, IQuery, IQueryIndicator, IRoundQueries } from 'src/app/models/query.interface';
import { debounceTime, filter, first, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ISectionItem } from 'src/app/models/section-item.interface';
import { ISectionItems, ScoreCssClass } from 'src/app/models/section-items.interface';
import { WebBundle } from 'src/app/models/web-bundle-commands/web-bundle';
import { ReviewSectionApiService } from 'src/app/services/review-section-api.service';
import { WebBundleStateService } from 'src/app/services/web-bundle-state.service';
import { FormQueryStateServiceFactory } from 'src/app/models/form-query-state/form-query-state-service-factory';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { FormScoreValue } from 'src/app/models/form-score-value.model';
import { ChangesDetectorService } from '../../../services/changes-detector.service';
import { QueryCreator } from 'src/app/services/query-creator';
import { QueryMarkerData } from 'src/app/models/query-marker';
import { AuthService } from 'src/app/services/auth.service';
import { QueriesService } from 'src/app/services/queries.service';
import { MaSpinnerService } from 'src/app/shared/ma-spinner.service';
import { QueryOrderService } from 'src/app/services/query-order.service';
import { SectionNavigationService } from 'src/app/services/section-navigation.service';
import { SubmitService } from 'src/app/services/submit.service';
import { DialogService } from '../../../services/dialog.service';

import {
    AUTO_STYLE,
    animate,
    state,
    style,
    transition,
    trigger
} from '@angular/animations';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'form-sections',
    templateUrl: './form-sections.component.html',
    styleUrls: ['./form-sections.component.scss'],
    animations: [
        trigger('collapse', [
            state('false', style({
                height: AUTO_STYLE,
                paddingTop: AUTO_STYLE,
                paddingBottom: AUTO_STYLE
            })),
            state('true', style({
                height: '0',
                paddingTop: '0',
                paddingBottom: '0'
            })),
            transition('false => true', animate(300 + 'ms ease-in-out')),
            transition('true => false', animate(300 + 'ms ease-in-out'))
        ])
    ]
})
export class FormSectionsComponent implements OnInit, OnDestroy {
    @Input() currentRound: number = 0;
    @Input() queries: IQuery[] = [];
    @Input() queryIndicators: IQueryIndicator[] = [];
    @Input() isViewMode: boolean;
    fieldValueChangedSubject: Subject<FormScoreValue> = new Subject<FormScoreValue>();
    fieldValueLoadedSubject: Subject<FormScoreValue> = new Subject<FormScoreValue>();
    queriesChanged$: Subject<IQuery[]> = new Subject<IQuery[]>();
    sectionMarkersChange$: Subject<void> = new Subject();

    sectionsItems: ISectionItems[] = [];
    sectionWithRawScoreId: string = '5ce1a98a-848d-46a5-98ed-5fad593b1556';
    sectionWithRawAndConvertedScoreId: string = '58bae8fe-3a9d-4734-9b49-d15fd31cb3f7';
    sectionWithRawAndRecommendedScoreId: string = '199e2b49-b8bc-474f-b8c2-7c1bb8378818';
    private webBundle: WebBundle;
    private fieldNames: string[] = [];
    private formId: string;
    private millisecondsBetweenUpdates = 1500;
    private areReviewerScoresRecieved: boolean = false;
    discrepancyQueryTypeId: string = FormQueryType.Discrepancy;
    fsScoreDiscrepancyTypeId: string = DiscrepancyType.FsScore;
    esScoreDiscrepancyTypeId: string = DiscrepancyType.EdssStep;

    private sectionsWithNotCompletedReviewComments: ISectionItems[] = [];
    private sectionQueries: Map<ISectionItems, IQuery[]> = new Map<ISectionItems, IQuery[]>();
    private sectionQueryIndicators: Map<ISectionItems, IQueryIndicator[]> = new Map<ISectionItems, IQueryIndicator[]>();
    private sectionQueriesByRounds: Map<ISectionItems, IRoundQueries[]> = new Map<ISectionItems, IRoundQueries[]>();

    private detectChanges$: Subject<void> = new Subject();
    private destroy$: Subject<void> = new Subject();

    private sectionResetChangesSubjects = new Map<ISectionItems, Subject<void>>();
    private sectionChangeSubjects = new Map<ISectionItems, Subject<QueryMarkerData>>();

    private formState: string;

    constructor(
        public reviewSectionApiService: ReviewSectionApiService,
        private route: ActivatedRoute,
        wbBundleState: WebBundleStateService,
        private dialogService: DialogService,
        private changesDetectorService: ChangesDetectorService,
        private authService: AuthService,
        private queryCreator: QueryCreator,
        private queriesService: QueriesService,
        private spinnerService: MaSpinnerService,
        private queryOrderService: QueryOrderService,
        private sectionNavigationService: SectionNavigationService,
        private submitService: SubmitService,
        private changeDetector: ChangeDetectorRef) {
        this.webBundle = wbBundleState.webBundle;
    }

    public getSectionResetObservable(section: ISectionItems): Observable<void> {
        const subject: Subject<void> = this.sectionResetChangesSubjects.has(section)
            ? this.sectionResetChangesSubjects.get(section)
            : new Subject();

        return subject.asObservable();
    }

    public getSectionUpdateObservable(section: ISectionItems): Observable<QueryMarkerData> {
        const subject: Subject<QueryMarkerData> = this.sectionChangeSubjects.has(section)
            ? this.sectionChangeSubjects.get(section)
            : new Subject();

        return subject.asObservable();
    }

    ngOnInit(): void {
        this.detectChanges$
            // The throttle time used because the form sends many events for fields loading and updating at one time.
            .pipe(debounceTime(20))
            .subscribe(() => this.changeDetector.detectChanges());

        this.queriesChanged$
            .pipe(
                tap(_ => {
                    this.sectionsItems.forEach(section => {
                        this.sortSectionItems();
                        const queries = this.sectionQueries.get(section);
                        const sectionQueriesByRounds = this.queriesService.updateRoundQueries(
                            this.sectionQueriesByRounds.get(section),
                            queries
                        );
                        this.sectionQueriesByRounds.set(
                            section,
                            sectionQueriesByRounds);
                        this.sectionChangeSubjects.get(section).next(this.getMarkersData(section, this.currentRound));
                    });
                }),
                debounceTime(this.millisecondsBetweenUpdates),
                takeUntil(this.destroy$))
            .subscribe(_ => this.webBundle.getFormState());

        this.formId = this.route.snapshot.params.formId;

        const spinner = this.spinnerService.show();

        this.route.data
            .pipe(
                first(),
                switchMap(data => {
                    this.formState = data.formInfo.formState;
                    return this.reviewSectionApiService.getReviewSections(this.formId);
                }),
                first(),
                switchMap(sectionsResponse => {
                    this.sectionsItems = sectionsResponse.sectionsItems;
                    this.fieldNames = sectionsResponse.queryNames;
                    this.extendFieldNamesWithAlgorithmic();
                    this.sortSectionItems();
                    this.sectionQueries.forEach((queries: IQuery[], section: ISectionItems) => {
                        this.sectionQueriesByRounds.set(
                            section,
                            this.queriesService.getRoundsQueries(queries));
                    });
                    this.sectionsItems.forEach(section => {
                        this.sectionResetChangesSubjects.set(section, new Subject());

                        const sectionMarkerUpdateSubject: Subject<QueryMarkerData> = new ReplaySubject(1);
                        this.sectionChangeSubjects.set(section, sectionMarkerUpdateSubject);
                        sectionMarkerUpdateSubject.next(this.getMarkersData(section, this.currentRound));
                    });

                    this.detectChanges();
                    return this.webBundle.formStateLoaded$;
                }),
                first(),
                takeUntil(this.destroy$))
            .subscribe(_ => {
                this.onWebBundleLoaded();
            }, error => {
                spinner.hide();
                throw new Error(error);
            },
            () => spinner.hide());

        this.sectionNavigationService.formNavigation$
            .subscribe(pageNumber => this.pickFormSection(pageNumber));
    }

    private onWebBundleLoaded() {
        this.webBundle.fieldValueChanged$
            .pipe(
                tap(_ => this.updateQueries()),
                filter(value => this.fieldNames.some(item => item === value.id)),
                takeUntil(this.destroy$))
            .subscribe(value => {
                this.fieldValueChangedSubject.next(value);
                this.setUpdatedScoreValues(value);
                this.setUpdatedScoreClass(value);
                this.detectChanges();
            });

        this.webBundle.fieldValue$
            .pipe(
                filter(value => this.fieldNames.some(item => item === value.id)),
                takeUntil(this.destroy$))
            .subscribe(value => {
                this.fieldValueLoadedSubject.next(value);
                this.setUpdatedScoreValues(value);
                this.setUpdatedScoreClass(value);
                this.detectChanges();
            });

        this.webBundle.formState$
            .pipe(
                takeUntil(this.submitService.needsPrepareSubmit$),
                tap(_ => {
                    if (!this.areReviewerScoresRecieved) {
                        this.getFieldsValues(this.fieldNames);
                        this.areReviewerScoresRecieved = true;
                    }
                }),
                filter(_ => !this.isViewMode),
                switchMap(formState => {
                    this.formState = formState;
                    const notEmptyQueries = this.queriesService.getNotEmptyQueries(this.queries);
                    return !!notEmptyQueries.length
                        ? this.reviewSectionApiService.updateQueries(this.formId, notEmptyQueries, formState)
                        : of(null);
                }),
                takeUntil(this.destroy$))
            .subscribe();

        this.submitService.needsPrepareSubmit$
            .pipe(
                switchMap(_ => {
                    const notEmptyQueries = this.queriesService.getNotEmptyQueries(this.queries);
                    return !!notEmptyQueries.length
                        ? this.reviewSectionApiService.updateQueries(this.formId, notEmptyQueries, this.formState)
                        : of(null);
                }),
                takeUntil(this.destroy$))
            .subscribe(_ => this.submitService.assessmentReadyToSubmit$.next());

        this.webBundle.getFormState();
    }

    private mapBySection<T>(
        sections: ISectionItems[],
        getItemsBySection: (section: ISectionItem) => T[]): Map<ISectionItems, T[]> {
            const result = new Map<ISectionItems, T[]>();

            sections.forEach(section => {
                let itemsBySection: T[] = [];
                section.sectionItems.forEach(sectionItem => {
                    itemsBySection = itemsBySection.concat(getItemsBySection(sectionItem));
                });
                result.set(section, itemsBySection);
            });

            return result;
    }

    private sortSectionItems(): void {
        const sectionQueries = this.mapBySection(this.sectionsItems, (section) => this.getSectionQueries(section));
        const sectionIndicators = this.mapBySection(this.sectionsItems, (section) => this.getSectionQueryIndicators(section));

        sectionQueries.forEach((queries: IQuery[], _: ISectionItems) => {
            this.queryOrderService.sortByQueries(queries, query => query);
        });
        this.sectionQueries = sectionQueries;

        sectionIndicators.forEach((indicators: IQueryIndicator[], _: ISectionItems) => {
            this.queryOrderService.sortByQueries(indicators, indicator => indicator.sourceQuery);
        });
        this.sectionQueryIndicators = sectionIndicators;
    }

    public isRaterScoresVisible(section: ISectionItems): boolean {
        return !!(section.rawScoreValue || section.convertedScoreValue);
    }

    public areUpdatedScoresVisible(section: ISectionItems): boolean {
        return !!(section.updatedRawScoreValue
            || section.updatedConvertedScoreValue)
            && section.updatedScoreCss !== ScoreCssClass.Invisible;
    }

    public hasPendingChanges(section: ISectionItems): boolean {
        const queries = this.getSortedQueries(section);
        return queries.some(q => q.isModified)
            || queries.some(q => q.queryThreads.filter(qt => !qt.isDeleted).some(qt => qt.isEditMode));
    }

    public getPrimaryScoreId(section: ISectionItems): string {
        return section.recommendedScoreItemId || section.rawScoreItemId;
    }

    public getPrimaryScoreName(section: ISectionItems): string {
        return section.recommendedScoreName || section.rawScoreName;
    }

    public deleteQuery(query: IQuery): void {
        const index = this.queries.indexOf(query);
        if (index > -1) {
            this.queries.splice(index, 1);
            this.sortSectionItems();
            this.updateQueries();
        }
    }

    public addComment(section: ISectionItems): void {
        if (!section.isExpanded) {
            this.toggleHeader(section);
        }

        this.sectionsWithNotCompletedReviewComments.push(section);

        const scoreId = this.getPrimaryScoreId(section);
        const scoreName = this.getPrimaryScoreName(section);
        const token = this.authService.getAuthorizationTokenValues();

        const newQuery = this.queryCreator.createQuery(scoreId, scoreName, '', token, this.currentRound, false);
        newQuery.isModified = true;
        newQuery.queryThreads[0].isEditMode = true;
        newQuery.queryThreads[0].timestamp = null;
        this.queries.push(newQuery);

        this.sortSectionItems();
        this.queriesService.addQuery(newQuery, this.sectionQueriesByRounds.get(section), this.currentRound);
        this.sectionChangeSubjects.get(section).next(this.getMarkersData(section, this.currentRound));
    }

    public ignorePendingChanges(section: ISectionItems): void {
        if (section.sectionItems) {
            const queryNames = section.sectionItems.map(item => item.queryName);
            const modifiedQuries = this.queries.filter(q => queryNames.includes(q.formQueryName) && q.isModified);
            modifiedQuries.forEach(query => {
                query.isModified = false;
                this.changesDetectorService.emitModifiedEvent(query);
            });
        }
    }

    public toggleHeader(item: ISectionItems): void {
        const newState = !item.isExpanded;
        const expandedSection = this.sectionsItems.find(section => section.isExpanded);
        if (expandedSection && this.hasPendingChanges(expandedSection)) {
            return;
        }
        this.sectionsItems.forEach(section => section.isExpanded = false);
        item.isExpanded = newState;
    }

    public pickFormSection(pageNumber: number, item?: ISectionItems): void {
        const expandedSection = this.sectionsItems.find(section => section.isExpanded && (!item || section !== item));
        if (expandedSection && this.hasPendingChanges(expandedSection)) {
            item = item ?? expandedSection;
            const dialog = this.dialogService.openConfirmPendingChangesDialog();
            dialog.overrideYes(() => {
                this.resetSectionChanges(item);
                this.webBundle.goToPage(pageNumber);
                dialog.close(true);
            });
        } else {
            this.webBundle.goToPage(pageNumber);
        }
    }

    public getSectionQueries(sectionItem: ISectionItem): IQuery[] {
        return this.queries.filter(q => this.isSupportedQuery(q, sectionItem));
    }

    public hasQueries(sectionItem: ISectionItem): boolean {
        return !!this.queries && this.queries.some(q => this.isSupportedQuery(q, sectionItem));
    }

    public hasSectionQueries(section: ISectionItems): boolean {
        return section.sectionItems.some(sectionItem => this.hasQueries(sectionItem));
    }

    public updateQueries(): void {
        this.sectionsWithNotCompletedReviewComments = this.sectionsItems.filter(si => si.sectionItems.some(item => {
            const hasQueries = this.hasQueries(item);
            const reviewQueries = this.getSectionQueries(item).filter(q => q.formQueryTypeId === FormQueryType.ReviewerComment);
            const hasEmptyReviewComments = reviewQueries.some(q => q.queryThreads.some(qt => !qt.text));

            return hasQueries && !!reviewQueries.length && hasEmptyReviewComments;
        }));

        this.queriesChanged$.next(this.queries);
    }

    ngOnDestroy(): void {
        this.detectChanges$.complete();
        this.destroy$.next();
        this.destroy$.complete();

        this.sectionResetChangesSubjects.forEach((subject: Subject<void>, _: ISectionItems) => {
            subject.complete();
        });

        this.sectionChangeSubjects.forEach((subject: Subject<QueryMarkerData>, _: ISectionItems) => {
            subject.complete();
        });
    }

    private setUpdatedScoreValues(value: FormScoreValue): void {
        this.sectionsItems.forEach((section) => {
            section.sectionItems.forEach((sectionItem) => {
                if (sectionItem.queryName === value.id) {
                    if (sectionItem.queryItemId === section.rawScoreItemId) {
                        section.updatedRawScoreValue = value.value;
                    } else if (sectionItem.queryItemId === section.convertedScoreItemId) {
                        section.updatedConvertedScoreValue = value.value;
                    } else {
                        section.recommendedScoreValue  = value.value;
                    }
                }
            });
        });
    }

    private getFieldsValues(fieldNames: string[]): void {
        this.webBundle.getFieldValues(fieldNames);
    }

    public isItemTypeExists(section: ISectionItems, queryTypeId: string, discrepancyTypeId: string): boolean {
        return this.sectionQueries.has(section)
            && !!this.sectionQueries
                .get(section)
                .filter(q =>
                    q.formQueryTypeId === queryTypeId
                    && q.formDiscrepancyTypeId === discrepancyTypeId)
                .length;
    }

    public canAddNewComment(section: ISectionItems): boolean {
        return this.sectionsWithNotCompletedReviewComments.every(s => s !== section);
    }

    public getSectionQueriesByRounds(section: ISectionItems): IRoundQueries[] {
        return this.sectionQueriesByRounds.has(section)
            ? this.sectionQueriesByRounds.get(section)
            : [];
    }

    private isSupportedQuery(query: IQuery, sectionItem: ISectionItem): boolean {
        const supportedQueryTypes = FormQueryStateServiceFactory.supportedDiscrapencyTypes;

        return query.formQueryName === sectionItem.queryName
            && ((query.formDiscrepancyTypeId === null && query.formQueryTypeId !== this.discrepancyQueryTypeId)
                || supportedQueryTypes.includes(query.formDiscrepancyTypeId));
    }


    private extendFieldNamesWithAlgorithmic(): void {
        const algorithmicFieldNames = this.fieldNames.map(fn => fn = fn + '.A');
        this.fieldNames = algorithmicFieldNames.concat(this.fieldNames);
    }

    private setUpdatedScoreClass(formScoreValue: FormScoreValue): void {
        this.sectionsItems.forEach((section) => {
            if (section.rawScoreName === formScoreValue.id) {
                const areRaterAndReviewerScoresEqual = section.rawScoreValue === formScoreValue.value;

                if (areRaterAndReviewerScoresEqual) {
                    section.updatedScoreCss = ScoreCssClass.Invisible;
                } else {
                    section.updatedScoreCss = '';
                }
            }
        })
    }

    private getSortedQueries(section: ISectionItems): IQuery[] {
        return this.sectionQueries.has(section)
            ? this.sectionQueries.get(section)
            : [];
    }

    private getSortedIndicators(section: ISectionItems): IQueryIndicator[] {
        return this.sectionQueryIndicators.get(section);
    }

    private getQueriesByRound(section: ISectionItems, roundNumber: number): IQuery[] {
        const sectionQueries = this.getSectionQueriesByRounds(section).find(sqr => sqr.round === roundNumber);
        return !!sectionQueries ? sectionQueries.queries : [];
    }

    private getMarkersData(section: ISectionItems, roundNumber: number): QueryMarkerData {
        return {
            queries: this.getQueriesByRound(section, roundNumber),
            indicators: this.getSortedIndicators(section)
        };
    }

    private getSectionQueryIndicators(sectionItem: ISectionItem): IQueryIndicator[] {
        return this.queryIndicators.filter(queryIndicator =>
            queryIndicator.itemName === sectionItem.queryName
            && !queryIndicator.sourceQuery.isResolved);
    }

    public resetSectionChanges(item: ISectionItems) {
        const newState = !item.isExpanded;
        const expandedSection = this.sectionsItems.find(section => section.isExpanded);
        expandedSection.isExpanded = !expandedSection.isExpanded;
        this.ignorePendingChanges(expandedSection);
        item.isExpanded = newState;

        const emptyQueries = this.queriesService.getEmptyQueries(
            this.sectionQueries.get(expandedSection));

        this.queries = this.queries
            .filter(query => emptyQueries.every(emptyQuery => emptyQuery !== query));

        const newSectionQueries = this.sectionQueries.get(expandedSection)
            .filter(sectionQuery => this.queries.some(query => sectionQuery === query));

        this.sectionQueries.set(expandedSection, newSectionQueries);
        this.sectionQueriesByRounds.set(
            expandedSection,
            this.queriesService.getRoundsQueries(newSectionQueries));
        this.sectionChangeSubjects.get(expandedSection).next(
            this.getMarkersData(expandedSection, this.currentRound));
        this.sectionResetChangesSubjects.get(expandedSection).next();

        this.sectionsWithNotCompletedReviewComments = this.sectionsWithNotCompletedReviewComments
            .filter(section => section !== expandedSection);
    }

    private detectChanges() {
        this.detectChanges$.next();
    }
}
