import { filter, first } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { WebBundleOutputCommands, WebBundleOutputCommandId } from './web-bundle-output-commands';
import { ReplaySubject, Subject } from 'rxjs';

@Injectable({ providedIn: "root" })
export class WebBundle extends WebBundleOutputCommands {
    fieldValue$: Subject<{id: string, value: string}>;
    fieldValueChanged$: Subject<{id: string, value: string}>;
    formState$: Subject<string>;
    formStateLoaded$: Subject<void> = new ReplaySubject(1);

    constructor() {
        super();
        this.fieldValue$ = new Subject<{id: string, value: string}>();
        this.fieldValueChanged$ = new Subject<{id: string, value: string}>();
        this.formState$ = new Subject<string>();
    }

    get pageLoaded$() {
        return this.handleCmd(WebBundleOutputCommandId.PageLoaded);
    }

    get needRescale$() {
        return this.handleCmd(WebBundleOutputCommandId.NeedRescale);
    }

    get modeChanged$() {
        return this.handleCmd(WebBundleOutputCommandId.ModeChanged);
    }

    get validationStatus$() {
        return this.handleCmd(WebBundleOutputCommandId.ValidationStatus);
    }

    switchAssessmentToEditMode() {
        // The change of $assessment_mode to empty value is needed here to reset the cached state of the form.
        // This allows the web bundle engine to react to the mode change and re-apply field locks.
        this.setFieldValue("$assessment_mode", "");
        this.setFieldValue("$assessment_mode", "1");
    }

    getFieldValues(fieldIds: string[]) {
        fieldIds.forEach(fieldId => super.getFieldValue(fieldId));
    }

    fieldValue(data: string) {
        if (data) {
           const parsed = JSON.parse(data) as {id: string, value: string};
           this.fieldValue$.next(parsed);
        }
    }

    fieldValueChanged(data: string) {
        if (data) {
           const parsed = JSON.parse(data) as {id: string, value: string};
           this.fieldValueChanged$.next(parsed);
        }
    }

    formStateLoaded(data: any) {
        this.formStateLoaded$.next();
    }

    handleNewFormState(state: string): void {
        if (state) {
            this.formState$.next(state);
        }
    }

    destroy() {
        super.destroy();
        this.fieldValue$.complete();
        this.formState$.complete();
        this.formStateLoaded$.complete();
    }

    private handleCmd(cmdId: WebBundleOutputCommandId) {
        return this.outCmd$.pipe(filter(cmd => cmd.cmdId === cmdId), first());
    }
}

export interface ISectionsStates { [sectionId: string]: boolean };
