<div class="actions-container">
    <div class="actions-holder" *ngIf="formDetails">
        <div class="dialog-header">
            <span class="dialog-title">Select action to proceed</span>
        </div>
        <div class="dialog-content">
            <dl>
                <dt>Study:</dt><dd>{{formDetails.study || '-'}}</dd>
                <dt>Site:</dt><dd>{{formDetails.site || '-'}}</dd>
                <dt>Visit:</dt><dd>{{formDetails.visit || '-'}}</dd>
                <dt>Subject:</dt><dd>{{formDetails.subject || '-'}}</dd>
                <dt>Assessment:</dt><dd>{{formDetails.assessment || '-'}}</dd>
                <dt>SVID:</dt><dd>{{formDetails.svid || '-'}}</dd>
            </dl>

            <ng-container *ngIf="!!informationMessage">
                <hr>
                <p [innerHTML]="informationMessage"></p>
            </ng-container>

            <div class="actions-box">
                <div class="holder">
                    <button type="button"
                            *ngIf="!formDetails.canContinueAssessment"
                            class="btn btn-combo btn-primary-light"
                            (click)="viewReview()">
                        <i class="icon icon-eye"></i>View assessment
                    </button>
                    <button type="button"
                            *ngIf="formDetails.canContinueAssessment"
                            class="btn btn-combo btn-success"
                            (click)="viewReview()">
                        <i class="icon icon-play"></i>Continue editing
                    </button>
                    <button type="button"
                            *ngIf="canOpenEditMode()"
                            class="btn btn-combo btn-success"
                            [disabled]="isEditingDisabled()"
                            (click)="editAssessment()">
                        <i class="icon icon-play"></i>Edit assessment
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>