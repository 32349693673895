import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { QueryMarker, QueryMarkerData } from 'src/app/models/query-marker';
import { IQuery, IQueryIndicator } from 'src/app/models/query.interface';
import { QueryMarkerService } from 'src/app/services/query-marker.service';

@Component({
    selector: 'app-form-query-markers',
    templateUrl: './form-query-markers.component.html'
})
export class FormQueryMarkersComponent implements OnInit, OnDestroy {
    @Input() currentRound = 0;
    @Input() updateMarkers: Observable<QueryMarkerData>;
    @Input() isTableMode: boolean = false;

    @Input() set queryMarkerData(data: QueryMarkerData) {
        if (data) {
            this.setQueryMarkers(data.queries, data.indicators);
        }
    }

    @Output() queryMarkerUpdated: EventEmitter<QueryMarker[]> = new EventEmitter<QueryMarker[]>();

    queryMarkers: QueryMarker[] = [];
    private destroy$: Subject<void> = new Subject();

    constructor(private queryMarkerService: QueryMarkerService) {
    }

    ngOnInit(): void {
        this.updateMarkers
            .pipe(
                filter(data => !!data.queries),
                takeUntil(this.destroy$))
            .subscribe(data => this.setQueryMarkers(data.queries, data.indicators));
    }

    private setQueryMarkers(queries: IQuery[], indicators: IQueryIndicator[]): void {
        const queryMarkers = this.queryMarkerService.getMarkers(queries, this.currentRound, indicators);

        if (this.isTableMode) {
            const tableViewQueryMarkers = [];
            tableViewQueryMarkers.length = this.queryMarkerService.supportedQueryMarkerTypesCount;
            queryMarkers.forEach(queryMarker => tableViewQueryMarkers[queryMarker.orderIndex] = queryMarker);
            this.queryMarkers = tableViewQueryMarkers;
        } else {
            this.queryMarkers = queryMarkers;
        }

        this.queryMarkerUpdated.next(this.queryMarkers);
    }

    ngOnDestroy(): void {
        this.destroy$.complete();
    }
}