import { GuidGenerator } from '../../services/guid-generator';
import { FormScoreValue } from '../form-score-value.model';
import { IQueryThread } from '../query.interface';
import { IToken } from '../token.interface';
import { CombinedRepliesFormQueryStateService } from './combined-replies-form-query-state.service';

export class FsFormQueryStateService extends CombinedRepliesFormQueryStateService {
    private isScoresEqual: boolean = false;

    public get queryType(): string {
        return 'FS';
    }

    onFormScoreChanged(
        reviewerScoreValue: string,
        raterScoreValue: string,
        scoreName: string,
        formScoreValue: FormScoreValue,
        token: IToken,
        round: number): void {
        const allReviewRoundQueryThreads = this.reviewRoundQueryThreads;
        const currentReviewRound = this.getReviewRoundQueryThread(round);
        const isLastReviewRound = allReviewRoundQueryThreads.indexOf(currentReviewRound) === allReviewRoundQueryThreads.length - 1;

        if (scoreName === formScoreValue.id && isLastReviewRound && !this.query.closeDate) {
            this.isScoresEqual = reviewerScoreValue === formScoreValue.value;
            const text = this.isScoresEqual
                ? `The rater agrees with the recommendation of the score ${reviewerScoreValue}.`
                : raterScoreValue === formScoreValue.value
                    ? 'The rater disagrees with the recommendation.'
                    : `The rater disagrees with the recommendation, and has changed the score to ${formScoreValue.value}.`;

            const queryThreadReplies = allReviewRoundQueryThreads[allReviewRoundQueryThreads.length - 1].replies;

            const raterComments = queryThreadReplies.filter(qt => !qt.isPersonReviewer);
            if (!!raterComments.length) {
                const relpyToDelete = raterComments[raterComments.length - 1];
                this.deleteQueryThread(relpyToDelete);
            }

            if (!!formScoreValue.value) {
                const newThread: IQueryThread = {
                    personFirstName: token.firstname,
                    personId: token.personId,
                    personLastName: token.lastname,
                    roundNumber: round,
                    edited: false,
                    extendedText: text,
                    manualText: '',
                    autoText: text,
                    text: text,
                    timestamp: this.dateTimeService.getServerCurrentDateUtc(),
                    queryId: this.query.queryId,
                    initial: false,
                    queryThreadId: GuidGenerator.newGuid(),
                    isClosingQueryThread: false,
                    isEditMode: false,
                    isAuto: true,
                    isPersonReviewer: false,
                    isNeedToUpdate: true,
                    isDeleted: false,
                    isAcknowledged: false
                };
                this.addQueryThreadToReview(newThread);
            }

            this.query.isResolved = this.isScoresEqual;
            this.queryStateUpdated.emit(this.query);
        }
    }

    public deleteQueryThread(queryThread: IQueryThread): void {
        const currentReviewRound = this.getReviewRoundQueryThread(queryThread.roundNumber);

        const reviewRoundQueryThreads = this.reviewRoundQueryThreads[this.reviewRoundQueryThreads.length - 1].replies;
        const lastQueryThread = reviewRoundQueryThreads[reviewRoundQueryThreads.length - 1];

        const isQueryAutoResolved = !!lastQueryThread && lastQueryThread.isAuto && this.isScoresEqual;
        const isQueryResolvedByComment = !!lastQueryThread && !lastQueryThread.isAuto;

        const isAutoThreadExists = currentReviewRound.replies.some(qt => qt.isAuto);

        this.query.isResolved = isQueryAutoResolved || (isQueryResolvedByComment && isAutoThreadExists);

        const deletedItemIndex = this.query.queryThreads.findIndex(qth => qth.queryThreadId === queryThread.queryThreadId);
        this.query.queryThreads[deletedItemIndex].isDeleted = true;
        this.queryStateUpdated.emit(this.query);

        this.groupQueryThreadsByRound();
    }
}
