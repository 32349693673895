import { IQueryReviewRound, IQueryThread } from '../query.interface';
import { BaseViewFormQueryStateService } from './base-view-form-query-state-service';

export class ReviewerCommentViewFormQueryStateService extends BaseViewFormQueryStateService {
    get markerElementCssClassName(): string {
        return this.formStateService.query.isAuto
            ? 'icon-change'
            : 'icon-comment';
    }

    get markerText(): string {
        return '';
    }

    canAddReply(queryReviewRound: IQueryReviewRound, currentRound: number): boolean {
        if (this.isClosed(queryReviewRound)) {
            return false;
        }
        return !this.formStateService.query.isResolved && queryReviewRound.round === currentRound;
    }

    isQueryResolutionRequired(): boolean {
        return !this.formStateService.query.isResolved;
    }

    public canManageRootQueryThread(queryReviewRound: IQueryReviewRound): boolean {
        const findElement = this.formStateService.getReviewRoundQueryThread(queryReviewRound.round);

        const lastReviewRoundIndex = this.formStateService.reviewRoundQueryThreads.length - 1;
        const currentReviewRoundIndex =  this.formStateService.reviewRoundQueryThreads.indexOf(findElement);

        return lastReviewRoundIndex === currentReviewRoundIndex;
    }

    public getDisplayText(queryThread: IQueryThread): string {
        return queryThread.extendedText;
    }
}
