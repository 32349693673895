import { catchError, delay } from 'rxjs/operators';
import { MaMessagesService } from '../shared/ma-messages.service'
import { ObservableInput, of, OperatorFunction } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from './cookie.service';
import { IEnvironmentSettings } from '../shared/environment-settings.interface';
import { UserActivityService } from './user-activity.service';

export abstract class BaseApiService {
    readonly raterApiAuthorizationUrl: string = 'raterapi/authorization';
    readonly reviewApiUrl: string = 'form-review';
    readonly templatesUrl: string = '/templates';
    readonly assessmentApiUrl: string = 'assessment';
    readonly noCacheHeader: HttpHeaders = new HttpHeaders({
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Access-Control-Allow-Origin': '*'

    });

    public authorizationApiUrl = `api/authorization`
    public readonly authorizationCookieName: string = "Medavante.Authorization.RaterApp";

    constructor(
        protected http: HttpClient,
        protected environmentSettings: IEnvironmentSettings,
        protected cookieSvc: CookieService,
        protected messagesSvc: MaMessagesService,
        protected userActivityService: UserActivityService) {
    }

    public navigateTo(url: string) {
        window.location.href = url;
    }

    public navigateToAuthorize() {
        this.cookieSvc.deleteCookie(this.authorizationCookieName);
        this.userActivityService.clearAssessmentActivity();

        const redirectUrl = encodeURIComponent(window.location.href);
        window.location.href = `${this.environmentSettings.authorizationUri}${this.authorizationApiUrl}/authorize/${document.location.host}?redirectUrl=${redirectUrl}`;
    }

    protected showError<T, R>(text: string, result: ObservableInput<R>, ignorableStatusCodes?: number[]): OperatorFunction<T, T | R> {
        return catchError(err => {
            if (ignorableStatusCodes?.includes(err.status)) {
                return result;
            } else if (err.error && err.error.errorCode) {
                return of(err.error);
            } else  {
                console.error(err);
                if (!err.status || err.status !== 403) {
                    this.messagesSvc.showError(text);
                }
            }

            return result;
        });
    }
}
