import { Injectable, NgModule, } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CookieService } from '../services/cookie.service';
import { JsonIsoDateParseInterceptor } from './json-iso-date-parse.interceptor';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { ServerDateTimeSyncInterceptor } from './server-date-time-sync.interceptor';
import { MultipleActiveSessionService } from './multiple-active-session.service';
import { MultipleActiveSessionInterceptor } from './multiple-active-session.interceptor';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {

    constructor(
        private cookieService: CookieService,
        private authServie: AuthService,
        private multipleActiveSessionService: MultipleActiveSessionService,
        private router: Router) {
    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = this.addAuthenticationToken(request);
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
            if (error instanceof HttpErrorResponse) {
                switch (error.status) {
                    case 401:
                        this.handleUnauthorizedError();
                        break;
                    case 403:
                        this.handleError(error.status);
                        break;
                    case 409:
                        this.multipleActiveSessionService.multipleSessionDetected$.next();
                        break;
                    default:
                }
            }

            return throwError(error);
        }));
    }

    private handleUnauthorizedError(): void {
        this.authServie.navigateToAuthorize();
    }

    private handleError(statusCode: number): void {
        this.router.navigate([`/error/${statusCode}`]);
    }

    private addAuthenticationToken(request: HttpRequest<any>) {
        const token = this.cookieService.get(this.authServie.authorizationCookieName);
        if (!token) {
            return request;
        }
        request = request.clone({
            withCredentials: false,
            setHeaders: {
                Authorization: `Bearer ${token}`
            }
        });

        return request;
    }
}

@NgModule({
    providers: [{
        provide: HTTP_INTERCEPTORS,
        useClass: ServerDateTimeSyncInterceptor,
        multi: true
    }, {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthorizationInterceptor,
        multi: true
    }, {
        provide: HTTP_INTERCEPTORS,
        useClass: MultipleActiveSessionInterceptor,
        multi: true
    }, {
        provide: HTTP_INTERCEPTORS,
        useClass: JsonIsoDateParseInterceptor,
        multi: true
    }]
})
export class InterceptorModule { }
