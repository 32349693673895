<div [ngClass]="{
        'expanded': isExpanded,
        'current-round': areQueriesInCurrentRound()
    }" class="item-block">

    <header class="item-panel">
        <button type="button" class="toggler" title="Toggle comment content" (click)="toggleRound()"></button>
        <span class="round-badge">{{getHeaderLabel()}}</span>
        <div class="item-col" [ngClass]="{'fade-out': isExpanded}">
            <app-form-query-markers
                [currentRound]="currentRound"
                [queryMarkerData]="queryMarkersData"
                [updateMarkers]="queryMarkersUpdated$.asObservable()">
            </app-form-query-markers>
        </div>
    </header>

    <div class="comments-container" [@collapse]="!isExpanded">
        <ng-container *ngFor="let reviewRoundQueryThread of getQueryReviewRounds()">
            <form-query-thread-tree
                [isExpanded]="isExpandedThread(reviewRoundQueryThread)"
                [isViewMode]="isViewMode"
                [currentRound]="currentRound"
                [query]="getQuery(reviewRoundQueryThread.queryId)"
                [reviewerScoreValue]="reviewerScoreValue"
                [raterScoreValue]="raterScoreValue"
                [changeableScoreName]="changeableScoreName"
                [onFieldValueChanged]="onFieldValueChanged"
                [onFieldValueLoaded]="onFieldValueLoaded"
                [queryReviewRound]="reviewRoundQueryThread"
                [resetChanges]="resetChanges"
                (queryDeleted)="onQueryDeleted($event)"
                (queryUpdated)="updateQueries($event)">
            </form-query-thread-tree>
        </ng-container>
    </div>
</div>