import { CommonModule } from '@angular/common';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { MaMessagesService } from './ma-messages.service';
import { MaSpinnerService } from './ma-spinner.service';
import { MatDialogModule } from '@angular/material';
import { MessageComponent } from './components/message/message.component';
import { NgModule } from '@angular/core';
import { OverlayModule } from '@angular/cdk/overlay';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { HelpComponent } from './components/help/help.component';
import { OutsideClick, InsertionDirective } from './directives';
import { SubmitFormComponent } from './components/submit-form/submit-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        SpinnerComponent,
        MessageComponent,
        ConfirmationComponent,
        ToolbarComponent,
        HelpComponent,
        OutsideClick,
        InsertionDirective,
        SubmitFormComponent,
    ],
    imports: [
        CommonModule,
        OverlayModule,
        MatDialogModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    providers: [
        MaSpinnerService,
        MaMessagesService,
    ],
    entryComponents: [
        SpinnerComponent,
        MessageComponent,
        ConfirmationComponent,
        ToolbarComponent,
        SubmitFormComponent
    ],
    exports: [
        ToolbarComponent,
        HelpComponent,
        OutsideClick,
        SubmitFormComponent,
        ConfirmationComponent,
    ]
})
export class SharedModule { }
