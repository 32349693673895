<main class="content-indented">
    <div class="content-holder">
        <div *ngIf="isBuildVersionLoaded()">
            <div class="study-title-wrap">
                <div class="table-title">
                Virgil {{getContentBuildVersion()}}
                </div>
            </div>
            <p>© MedAvante-ProPhase {{getCurrentYear()}}. All Rights Reserved</p>
        </div>
        <div class="text-holder">
            <strong class="title">Legal Notice</strong>
            <p>This program is the confidential and proprietary product of MedAvante-ProPhase. Neither this program nor the information contained in this program may be disclosed to any third party. No portion of this program may be used for any purpose or transferred or reproduced in any form or by any means without the prior written permission of MedAvante-ProPhase in each instance. This program is subject to the additional terms, conditions and restrictions of a license agreement with MedAvante-ProPhase.</p>
        </div>
    </div>
</main>