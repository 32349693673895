import { Injectable } from '@angular/core';
import { FormInfo } from '../models/form-info.model';
import { WebBundle } from 'src/app/models/web-bundle-commands/web-bundle';
import { WebBundleMode } from '../models/web-bundle-commands/web-bundle-input-commands';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class WebBundleStateService {
    webBundleLoaded$: Subject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(public webBundle: WebBundle) { }

    setFormToShow({ formState, webBundleMode }: FormInfo): void {
        this.webBundle.setFormState(formState);
        this.setWebBundleToMode(webBundleMode);
    }

    setLoadedState(value: boolean) {
        this.webBundleLoaded$.next(value);
    }

    destroy() {
        this.webBundleLoaded$.complete();
    }

    canSubmitWithErrors() {
        this.webBundle.getCanSubmitWithErrors();
        return this.webBundle.canSubmitWithErrors$;
    }

    getFormErrorCount() {
        this.webBundle.getFormErrorCount();
        return this.webBundle.getFormErrorCount$;
    }

    private setWebBundleToMode(webBundleMode: WebBundleMode): void {
        if (webBundleMode === WebBundleMode.Edit) {
            this.webBundle.switchWebBundleToEditMode();
        }

        this.webBundle.enableValidation();
        this.webBundle.switchAssessmentToEditMode();
    }
}
