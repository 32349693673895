import { BaseApiService } from './base-api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { MaMessagesService } from '../shared/ma-messages.service';
import { Observable, of } from 'rxjs';
import { CookieService } from './cookie.service';
import { ISectionsItemsResponse } from '../models/sections-items-response.interface';
import { IQuery, QueryIndicator } from '../models/query.interface';
import { IEnvironmentSettings } from '../shared/environment-settings.interface';
import { UserActivityService } from './user-activity.service';
import { map, tap } from 'rxjs/operators';
import { MultipleActiveSessionService } from './multiple-active-session.service';
import { serverTimeHeaderName } from './server-date-time-sync.interceptor';
import { UserActivityModel } from '../models/user-activity.model';

@Injectable({ providedIn: 'root' })
export class ReviewSectionApiService extends BaseApiService {
    public readonly reviewSectionApiUrl = 'review-section';

    constructor(
        @Inject('ENV_SETTINGS') environmentSettings: IEnvironmentSettings,
        protected http: HttpClient,
        protected cookieSvc: CookieService,
        messagesSvc: MaMessagesService,
        userActivityService: UserActivityService,
        private multipleActiveSessionService: MultipleActiveSessionService) {
        super(
            http,
            environmentSettings,
            cookieSvc,
            messagesSvc,
            userActivityService);
    }

    getReviewSections(formId: string): Observable<ISectionsItemsResponse> {
        const url = `${this.environmentSettings.raterApi}${this.reviewSectionApiUrl}/${formId}`;
        return this.http
            .get<ISectionsItemsResponse>(url)
            .pipe(this.showError('Failed to get sections', of(null)));
    }

    getQueries(formId: string): Observable<IQuery[]> {
        const url = `${this.environmentSettings.raterApi}${this.reviewSectionApiUrl}/${formId}/queries`;
        return this.http
            .get<IQuery[]>(url, { observe: 'response'})
            .pipe(
                tap(response => {
                    const serverDateValue = response.headers.get(serverTimeHeaderName);
                    this.multipleActiveSessionService.lastPullTime = serverDateValue;
                }),
                map(response => this.mapQueries(response.body)),
                this.showError('Failed to get queries', of(null))
            );
    }

    updateQueries(formId: string, queries: IQuery[], formState: string): Observable<string> {
        const url = `${this.environmentSettings.raterApi}${this.reviewSectionApiUrl}/${formId}/queries`;
        return this.http
            .post<string>(url, { queries: this.queriesToRequest(queries), formState })
            .pipe(this.showError('Failed to update queries', of(null), [409]));
    }

    startUserActivitySession(formId: string): Observable<void> {
        const request: UserActivityModel = {
            pullDateTime: this.multipleActiveSessionService.lastPullTime
        };
        const url = `${this.environmentSettings.raterApi}${this.reviewSectionApiUrl}/${formId}/start-user-activity-session`;
        return this.http
            .post(url, request)
            .pipe(this.showError('Failed to start user activity session', of(null)));
    }

    private queriesToRequest(queries: IQuery[]): IQuery[] {
        return queries.map(query => {
            const newQuery = Object.assign({}, query);
            newQuery.queryIndicators = newQuery.queryIndicators.map(indicator => {
                const newQueryIndicator = Object.assign({}, indicator);
                newQueryIndicator.sourceQuery = null;
                return newQueryIndicator;
            });
            return newQuery;
        });
    }

    private mapQueries(queries: IQuery[]): IQuery[] {
        queries.filter(query => !!query.queryIndicators && !!query.queryIndicators.length)
            .forEach(query => {
                query.queryIndicators = query.queryIndicators.map(queryIndicator => new QueryIndicator(
                    queryIndicator.itemName,
                    query));
            });

        return queries;
    }
}


