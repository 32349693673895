<div class="legend-content">
    <div class="heading-block">
        <strong class="title">There are items in this assessment that require your attention </strong>
        <ol>
            <li>Select a section.</li>
            <li>Provide your input to the scores.</li>
            <li>Optionally respond to queries.</li>
        </ol>
    </div>
    <div class="legend-holder">
        <div class="legend-column wide">
            <div class="legend-block">
                <strong class="title">Discrepancies type</strong>
                <ul>
                    <li>
                        <ul class="markers-list">
                            <li>
                                <span class="marker-element danger">
                                    <span class="text">FS</span>
                                </span>
                            </li>
                        </ul>
                        <span class="text-holder">FS score to sub-scores discrepancy</span>
                    </li>
                    <li>
                        <ul class="markers-list">
                            <li>
                                <span class="marker-element danger">
                                    <span class="text">ES</span>
                                </span>
                            </li>
                            <li>
                                <span class="marker-element empty danger">
                                    <span class="text">ES</span>
                                </span>
                            </li>
                        </ul>
                        <span class="text-holder">EDSS step discrepancy and indicator</span>
                    </li>
                    <li>
                        <ul class="markers-list">
                            <li>
                                <span class="marker-element primary">
                                    <span class="text">SS</span>
                                </span>
                            </li>
                        </ul>
                        <span class="text-holder">Section sub-scores discrepancy</span>
                    </li>
                    <li>
                        <ul class="markers-list">
                            <li>
                                <span class="marker-element warning">
                                    <span class="text">XC</span>
                                </span>
                            </li>
                            <li>
                                <span class="marker-element empty warning">
                                    <span class="text">XC</span>
                                </span>
                            </li>
                        </ul>
                        <span class="text-holder">Across section sub-scores discrepancy and indicator</span>
                    </li>
                    <li>
                        <ul class="markers-list">
                            <li>
                                <span class="marker-element success">
                                    <span class="text">p/t</span>
                                </span>
                            </li>
                        </ul>
                        <span class="text-holder">P/T Markers</span>
                    </li>
                </ul>
            </div>
            <div class="legend-block">
                <ul>
                    <li>
                        <i class="icon-change danger"></i>
                        <span class="text-holder">Score change suggestion</span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="legend-column">
            <div class="legend-block">
                <strong class="title">Scores</strong>
                <ul>
                    <li>
                        <div class="num-box">
                            <em class="main-elem">0</em>
                            <em class="additional-elem">3</em>
                        </div>
                        <span class="text-holder">Raw and converted score</span>
                    </li>
                    <li>
                        <div class="num-box">
                            <em class="main-elem">0</em>
                        </div>
                        <span class="text-holder">Score</span>
                    </li>
                </ul>
            </div>
            <div class="legend-block">
                <strong class="title">Comments type</strong>
                <ul>
                    <li>
                        <i class="icon-comment danger"></i>
                        <span class="text-holder">New comment</span>
                    </li>
                    <li>
                        <i class="icon-comment"></i>
                        <span class="text-holder">Resolved comment</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>