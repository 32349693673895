<div class="content-holder">
    <div class="content-frame">
        <header class="general-info-panel">
            <ul class="info-list" *ngIf="formDetails">
                <li class="large-item">
                    <span>Study:</span>
                    <strong>{{formDetails.study}}</strong>
                </li>
                <li class="large-item">
                    <span>Patient:</span>
                    <strong>{{formDetails.subject}}</strong>
                </li>
            </ul>
            <ul class="popups-list">
                <li outsideClick (outsideClickEvent)="closeDetails()" [ngClass]="{'opened': isDetailsPopupOpened}">
                    <span class="popup-opener info" (click)="toggleDetails()">All details</span>
                    <div class="popup popup-info" *ngIf="formDetails">
                        <div class="holder">
                            <ul>
                                <li>
                                    <span class="title">Site:</span>
                                    <span class="value">{{formDetails.site}}</span>
                                </li>
                                <li>
                                    <span class="title">Country:</span>
                                    <span class="value">{{formDetails.country}}</span>
                                </li>
                                <li>
                                    <span class="title">Visit:</span>
                                    <span class="value">{{formDetails.visit}}</span>
                                </li>
                                <li>
                                    <span class="title">Assessment:</span>
                                    <span class="value">{{formDetails.assessment}}</span>
                                </li>
                                <li>
                                    <span class="title">SVID:</span>
                                    <span class="value">{{formDetails.svid}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </li>
                <li outsideClick (outsideClickEvent)="closeHelpWindow()" [ngClass]="{'opened': isHelpPopupOpened}">
                    <span class="popup-opener legend" (click)="toggleHelpWindow()">Help</span>
                    <help class="popup"></help>
                </li>
            </ul>
        </header>
        <form-sections *ngIf="formDetails"
            [isViewMode]="isViewMode()"
            [queries]="queries"
            [currentRound]="formDetails.round"
            [queryIndicators]="queryIndicators">
        </form-sections>
    </div>
    <div class="buttons-holder" *ngIf="formDetails">
        <button class="btn btn-success" type="button" title="Edit" *ngIf="canOpenEditMode()" [disabled]="!canUserEditForm() || isEditingDisabled()" (click)="editAssessment()">
            Edit assessment
        </button>
        <button class="btn btn-success" type="button" title="Submit" *ngIf="isEditMode()" [disabled]="isSubmitted || !areQueriesResolved()" (click)="submitReview()">
            Submit
        </button>
    </div>
</div>
