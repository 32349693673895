<div>
    <button class="btn"
            type="button"
            title="Go to start"
            [disabled]="!canGoFirstPage"
            (click)="goFirstPage()">
        <i class="icon icon-go-to-start"></i>
    </button>
    <button class="btn"
            type="button"
            title="Previous page"
            [disabled]="!canGoPrevPage"
            (click)="goPrevPage()">
        <i class="icon icon-chevron-left"></i>
    </button>
</div>

<div class="paging">
    <span>{{currentPageNumber}}</span> of <span>{{pagesCount}}</span>
</div>

<div>
    <button class="btn btn-icon"
            type="button"
            title="Next page"
            [disabled]="!canGoNextPage"
            (click)="goNextPage()">
        <i class="icon icon-chevron-right"></i>
    </button>
    <button class="btn btn-icon"
            type="button"
            title="Go to end"
            [disabled]="!canGoLastPage"
            (click)="goLastPage()">
        <i class="icon icon-go-to-end"></i>
    </button>
</div>