import { Component } from '@angular/core';
import { WebBundle } from 'src/app/models/web-bundle-commands/web-bundle';
import { SectionNavigationService } from 'src/app/services/section-navigation.service';
import { WebBundleStateService } from 'src/app/services/web-bundle-state.service';

@Component({
  selector: 'nav-panel',
  templateUrl: './nav-panel.component.html',
  styleUrls: ['./nav-panel.component.scss']
})
export class NavPanelComponent {
  private webBundle: WebBundle;

  get currentPageNumber(): number {
    return this.webBundle.currentPageNumber;
  }

  get pagesCount(): number {
    return this.webBundle.pagesCount;
  }


  get canGoFirstPage() {
    return this.currentPageNumber > 1;
  }
  goFirstPage() {
    this.sectionNavigationService.formNavigation$.next(1);
  }

  get canGoPrevPage() {
    return this.currentPageNumber > 1;
  }
  goPrevPage() {
    this.sectionNavigationService.formNavigation$.next(this.currentPageNumber - 1);
  }


  get canGoNextPage() {
    return this.currentPageNumber < this.pagesCount;
  }
  goNextPage() {
    this.sectionNavigationService.formNavigation$.next(this.currentPageNumber + 1);
  }

  get canGoLastPage() {
    return this.currentPageNumber < this.pagesCount;
  }
  goLastPage() {
    this.sectionNavigationService.formNavigation$.next(this.pagesCount);
  }


  constructor(wbBundleState: WebBundleStateService,
    private sectionNavigationService: SectionNavigationService) {
    this.webBundle = wbBundleState.webBundle;
  }
}
