<div class="nav-wrap">
	<h1 class="logo">
		<img src="/assets/images/logo.svg" width="114" height="43" alt="WCG Virgil">
	</h1>
	<div class="version-notes">
		<span>{{buildVersionView}}</span>
	</div>
</div>
<div class="top-actions" *ngIf="isHeaderActionsVisible">
	<!-- TODO: Add notifications logic in future -->
	<!-- <div class="notifications-holder" [class.disabled]="true">
		<div class="notifications-box">
			<i class="icon-alert"></i>
			<span class="notifications-indicator"></span>
		</div>
	</div> -->
	<div class="user-actions" outsideClick (outsideClickEvent)="closeMenu()" [class.opened]="dropdownIsOpened">
		<button type="button" class="user-opener" (click)='toggleOpenState()'>
			<strong>{{firstName}} {{lastName}}</strong>
		</button>
		<ul class="dropdown">
			<li>
				<a href="http://www.medavante.com/privacy-policy/" target="_blank">
					<span class="item-icon">
						<i class="icon-policy"></i>
					</span>
					<span class="text-frame">Privacy Policy <i class="icon icon-external-link"></i></span>
				</a>
			</li>
			<li>
				<a [href]="'#/about'" target="_blank">
					<span class="item-icon">
						<i class="icon-question"></i>
					</span>
					<span class="text-frame">About <i class="icon icon-external-link"></i></span>
				</a>
			  </li>
			<li>
				<a href="javascript:void(0)" (click)="logout()">
					<span class="item-icon logout">
						<i class="icon-logout"></i>
					</span>
					<span class="text-frame">Log out</span>
				</a>
			</li>
		</ul>
	</div>
</div>
