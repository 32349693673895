import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BuildVersionModel } from 'src/app/models/buildVersion.model';
import { IEnvironmentSettings } from '../shared/environment-settings.interface';
import { MaMessagesService } from '../shared/ma-messages.service';
import { BaseApiService } from './base-api.service';
import { CookieService } from './cookie.service';
import { UserActivityService } from './user-activity.service';

@Injectable({
    providedIn: 'root'
})
export class ApiInfoService extends BaseApiService {
    readonly infoApiUrl: string = 'info';

    constructor(
        @Inject('ENV_SETTINGS') environmentSettings: IEnvironmentSettings,
        protected http: HttpClient,
        protected cookieSvc: CookieService,
        messagesSvc: MaMessagesService,
        userActivityService: UserActivityService) {
        super(
            http,
            environmentSettings,
            cookieSvc,
            messagesSvc,
            userActivityService);
    }

    getBuildVersion(): Observable<BuildVersionModel>{
        return this.http.get<BuildVersionModel>(`${this.environmentSettings.raterApi}${this.infoApiUrl}/build-version`)
            .pipe(this.showError('Failed to get build-version', of<any>(null)));
    }
}
