import { QueryThreadFormValue } from '../query-thread-form-value.model';
import { IQueryThread } from '../query.interface';
import { AcknowledgeViewQueryStateService } from './acknowledge-view-query-state.service';

export class XcViewFormQueryStateService extends AcknowledgeViewQueryStateService {
    get notResolvedQueryCssClassName(): string {
        return 'warning';
    }

    isEnabledSaveAddForm(queryThreadFormValue: QueryThreadFormValue): boolean {
        return !!queryThreadFormValue?.text.trim()
            && super.isEnabledSaveAddForm(queryThreadFormValue);
    }

    isEnabledSaveEditForm(queryThread: IQueryThread, queryThreadFormValue: QueryThreadFormValue): boolean {
        return !!queryThreadFormValue?.text.trim()
            && super.isEnabledSaveEditForm(queryThread, queryThreadFormValue);
    }

    isReplyTextRequired() {
        return true;
    }
}
