import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { IQuery, IQueryReviewRound, IQueryThread } from 'src/app/models/query.interface';
import { FormQueryStateServiceFactory } from 'src/app/models/form-query-state/form-query-state-service-factory';
import { IFormQueryStateService } from 'src/app/interfaces/form-query-state/form-query-state-service.interface';
import { QueryThreadFormComponent } from '../query-thread-form/query-thread-form.component';
import { IViewFormQueryStateService } from 'src/app/interfaces/form-query-state/view-query-state-service.interface';
import { Observable, Subject } from 'rxjs';
import { FormScoreValue } from 'src/app/models/form-score-value.model';
import { IToken } from 'src/app/models/token.interface';
import { QueryThreadFormValue } from 'src/app/models/query-thread-form-value.model';
import { Constants } from 'src/app/shared/constants';
import { ChangesDetectorService } from '../../../services/changes-detector.service';
import { DateTimeService } from 'src/app/shared/date-time.service';
import { AuthService } from 'src/app/services/auth.service';
import { takeUntil } from 'rxjs/operators';
import {
    AUTO_STYLE,
    animate,
    state,
    style,
    transition,
    trigger
} from '@angular/animations';

@Component({
    selector: 'form-query-thread-tree',
    templateUrl: './form-query-thread-tree.component.html',
    styleUrls: ['./form-query-thread-tree.component.scss'],
    animations: [
        trigger('collapse', [
            state('false', style({
                height: AUTO_STYLE
            })),
            state('true', style({
                height: '0'
            })),
            transition('false => true', animate(300 + 'ms ease-in-out')),
            transition('true => false', animate(300 + 'ms ease-in-out'))
        ])
    ]
})
export class FormQueryThreadTreeComponent implements OnInit, OnDestroy {
    @Input() queryReviewRound: IQueryReviewRound;

    @Input() query: IQuery = null;
    @Input() currentRound: number;
    @Input() isViewMode: boolean;
    @Input() reviewerScoreValue: string;
    @Input() raterScoreValue: string;
    @Input() changeableScoreName: string;
    @Input() onFieldValueChanged: Observable<FormScoreValue>;
    @Input() onFieldValueLoaded: Observable<FormScoreValue>;
    @Input() isExpanded: boolean;
    @Input() resetChanges: Observable<void>;

    @Output() queryDeleted: EventEmitter<IQuery> = new EventEmitter<IQuery>();
    @Output() queryUpdated: EventEmitter<IQuery> = new EventEmitter<IQuery>();

    @ViewChildren(QueryThreadFormComponent)
    queryThreadForms: QueryList<QueryThreadFormComponent>;

    private newQueryThreadValue: QueryThreadFormValue = null;
    private formStateService: IFormQueryStateService;
    private viewFormStateService: IViewFormQueryStateService;

    private userToken: IToken;

    private editedQueryThreadValueMap: Map<IQueryThread, QueryThreadFormValue> =
        new Map<IQueryThread, QueryThreadFormValue>();

    private destroy$: Subject<void> = new Subject();

    public dateFormat: string = 'y-MMM-ddThh:mm';
    public dateTimeFormat: string = 'dd-MMM-y HH:mm';

    constructor(
        private formStateServiceFactory: FormQueryStateServiceFactory,
        private changesDetectorService: ChangesDetectorService,
        private authService: AuthService,
        private dateTimeService: DateTimeService) {}

    public get queryType(): string {
        return this.formStateService.queryType;
    }

    public get isResolved(): boolean {
        return this.formStateService.isResolved(this.queryReviewRound);
    }

    public get isClosed(): boolean {
        return this.query.closeDate != null || this.formStateService.isClosed(this.queryReviewRound);
    }

    public get canBeClosed(): boolean {
        return this.formStateService.canBeClosed;
    }

    public canDeleteQueryThread(queryThread: IQueryThread): boolean {
        return this.viewFormStateService.canDeleteQueryThread(queryThread, this.currentRound);
    }

    public get canAddReply(): boolean {
        return this.viewFormStateService.canAddReply(
            this.queryReviewRound,
            this.currentRound);
    }

    public get notResolvedQueryCssClassName(): string {
        return this.viewFormStateService.notResolvedQueryCssClassName;
    }

    public get markerElementCssClassName(): string {
        return this.viewFormStateService.markerElementCssClassName;
    }

    public get markerText(): string {
        return this.viewFormStateService.markerText;
    }

    ngOnInit() {
        this.userToken = this.authService.getAuthorizationTokenValues();

        this.formStateService = this.formStateServiceFactory.getQueryStateService(this.query, this.currentRound);
        this.viewFormStateService = this.formStateServiceFactory.getViewQueryStateService(this.formStateService);

        if (!this.isViewMode) {
            this.onFieldValueChanged
                .pipe(takeUntil(this.destroy$))
                .subscribe(newValue => this.formStateService.onFormScoreChanged(
                    this.reviewerScoreValue,
                    this.raterScoreValue,
                    this.changeableScoreName,
                    newValue,
                    this.userToken,
                    this.currentRound));

            this.onFieldValueLoaded
                .pipe(takeUntil(this.destroy$))
                .subscribe(newValue => this.formStateService.onFormScoreLoaded(
                    this.reviewerScoreValue,
                    this.raterScoreValue,
                    this.changeableScoreName,
                    newValue,
                    this.userToken,
                    this.currentRound));
        }

        this.formStateService.queryStateUpdated
            .pipe(takeUntil(this.destroy$))
            .subscribe(query => {
                this.setQueryModifiedState(query);
                this.queryReviewRound = this.formStateService.getReviewRoundQueryThread(this.queryReviewRound.round);
                this.queryUpdated.next(query);
            });

        this.resetChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.queryReviewRound.root.isEditMode = false;
                this.queryReviewRound.replies.forEach(qrr => qrr.isEditMode = false);
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public onEditFormChanged(queryThread: IQueryThread, value: QueryThreadFormValue) {
        this.editedQueryThreadValueMap.set(queryThread, value);
    }

    public onAddFormChanged(value: QueryThreadFormValue) {
        this.newQueryThreadValue = value;
        this.setQueryModifiedState(this.query);
    }

    public isEnabledSaveAddForm(): boolean {
        return this.viewFormStateService.isEnabledSaveAddForm(this.newQueryThreadValue);
    }

    public isResolutionRequired(): boolean {
        return this.viewFormStateService.isQueryResolutionRequired();
    }

    public get isReplyTextRequired(): boolean {
        return this.viewFormStateService.isReplyTextRequired();
    }

    public isEnabledSaveEditForm(queryThread: IQueryThread): boolean {
        if (!this.editedQueryThreadValueMap.has(queryThread)) {
            return false;
        }

        const formValue = this.editedQueryThreadValueMap.get(queryThread);
        return this.viewFormStateService.isEnabledSaveEditForm(queryThread, formValue);
    }

    public isAddFormVisible() {
        return this.queryReviewRound.replies.every(qt => qt.isEditMode !== true);
    }

    public isAuthor(queryThread: IQueryThread): boolean {
        return queryThread.personId === this.userToken.personId;
    }

    public canManageQueryThread(queryThread: IQueryThread): boolean {
        return this.isAuthor(queryThread)
            && this.viewFormStateService.canManageQueryThread(queryThread, this.currentRound);
    }

    public canManageRootQueryThread(): boolean {
        return !this.isViewMode
            && this.isAuthor(this.queryReviewRound.root)
            && this.canManageQueryThread(this.queryReviewRound.root)
            && this.queryReviewRound.replies.length === 0
            && this.viewFormStateService.canManageRootQueryThread(this.queryReviewRound);
    }

    public canDeleteRootQueryThread(queryThread: IQueryThread): boolean {
        return this.viewFormStateService.canDeleteRootQueryThread(queryThread);
    }

    public canAcknowledge(queryThread: IQueryThread): boolean {
        return this.viewFormStateService.canAcknowledge(queryThread, this.currentRound);
    }

    public saveQueryThreadChanges(queryThread: IQueryThread, value: QueryThreadFormValue): void {
        this.formStateService.saveQueryThreadChanges(
            this.userToken,
            queryThread,
            value.text,
            value.isAcknowledged);
        queryThread.isNeedToUpdate = true;
        this.switchEditMode(queryThread);
        this.editedQueryThreadValueMap.delete(queryThread);
    }

    public deleteQuery(): void {
        this.queryDeleted.next(this.query);
    }

    public deleteQueryThread(queryThread: IQueryThread): void {
        this.editedQueryThreadValueMap.delete(queryThread);
        this.formStateService.deleteQueryThread(queryThread);
    }

    public addNewQueryThread(value: QueryThreadFormValue) {
        this.formStateService.addNewQueryThread(
            this.userToken,
            this.currentRound,
            value.text,
            value.isAcknowledged);

        this.newQueryThreadValue = null;
    }

    public switchEditMode(queryThread: IQueryThread) {
        queryThread.isEditMode = !queryThread.isEditMode;
        this.editedQueryThreadValueMap.delete(queryThread);
        this.setQueryModifiedState(this.query);
    }

    public switchExpandMode() {
        this.isExpanded = !this.isExpanded;
    }

    public submitQueryThread(queryThread: IQueryThread): void {
        this.queryThreadForms.find(form => form.queryThread === queryThread).submit();
    }

    public submitQueryThreadReply(): void {
        this.queryThreadForms.find(form => !form.queryThread).submit();
    }

    public getAuthorName(queryThread: IQueryThread): string {
        if (queryThread.isPersonReviewer) {
            return Constants.reviewerAuthor;
        }

        return this.isSystemUser(queryThread)
            ? `${queryThread.personFirstName}`
            : `${queryThread.personFirstName} ${queryThread.personLastName}`;
    }

    public getLocalTimestamp(queryThread: IQueryThread): Date {
        return this.dateTimeService.toLocalDate(queryThread.timestamp);
    }

    public getDisplayText(queryThread: IQueryThread): string {
        return this.viewFormStateService.getDisplayText(queryThread);
    }

    public getReadOnlyText(queryThread: IQueryThread): string {
        return this.viewFormStateService.getReadOnlyText(queryThread);
    }

    public getEditableText(queryThread: IQueryThread): string {
        return this.viewFormStateService.getEditableText(queryThread);
    }

    public isClosedBySystem(queryThead: IQueryThread): boolean {
        return queryThead.isClosingQueryThread && this.isSystemUser(queryThead);
    }

    public isClosedNotBySystem(queryThead: IQueryThread): boolean {
        return queryThead.isClosingQueryThread && !this.isSystemUser(queryThead);
    }

    public canBeExpanded(): boolean {
        return !!this.queryReviewRound.replies.length;
    }

    private isSystemUser(queryThread: IQueryThread): boolean {
        return queryThread.personId === Constants.systemUserId;
    }

    private setQueryModifiedState(query: IQuery) {
        query.isModified = this.isEnabledSaveAddForm()
            || query.queryThreads.some(qt => qt.isEditMode);
        this.changesDetectorService.emitModifiedEvent(query);
    }
}
