import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { DialogService } from '../services/dialog.service';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable({
  providedIn: 'root'
})
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
    constructor(private dialogService: DialogService) {}

    canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
        const canDeactivate = component.canDeactivate;

        if (!canDeactivate) {
            return true;
        }

        if (!canDeactivate()) {
            this.dialogService.openConfirmPendingChangesDialog();
        }

        return canDeactivate();
    }
}
