import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SubmitService {
    private submitResultSubj$: Subject<boolean> = new BehaviorSubject(false);

    public needsPrepareSubmit$ = new Subject();
    public assessmentReadyToSubmit$ = new Subject();

    public setSubmitResult(isSubmitted: boolean): void {
        this.submitResultSubj$.next(isSubmitted);
    }

    public get submitResult$(): Observable<boolean> {
        return this.submitResultSubj$;
    }
}
