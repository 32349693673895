<header class="header">
    <toolbar [isHeaderActionsVisible]="visibleHeaderActions"></toolbar>
</header>
<div class="content">
    <router-outlet></router-outlet>
</div>
<div [ngClass]="{'visible': isViewerLoaded && isVScrolled}" class="global-warning-box">
    <div class="global-warning-frame">
        <span>Screen size is too small to support this application. Please maximize the size of your web browser or choose a device with a larger screen.</span>
    </div>
</div>
