import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IToken } from '../models/token.interface';
import { MaSpinnerService } from '../shared/ma-spinner.service';

const formKeyPrefix: string = 'assessment-activity-';

@Injectable({ providedIn: 'root' })
export class UserActivityService {
    constructor(private spinnerService: MaSpinnerService, private router: Router) {
    }

    public addAssessmentActivity(formId: string, userToken: IToken): void {
        const keyName = this.getKeyName(formId);
        localStorage.setItem(keyName, userToken.personId);
    }

    public clearAssessmentActivity(): void {
        const activeAssessmentKeys = Object.keys(localStorage).filter(key => key.startsWith(formKeyPrefix));
        activeAssessmentKeys.forEach(key => localStorage.removeItem(key));
    }

    public checkAssessmentActivity(formId: string, userToken: IToken): boolean {
        const hasAssessmentActivity = this.hasAssessmentActivity(formId, userToken);
        if (!hasAssessmentActivity) {
            this.router.navigate([`/${formId}`]);
        }

        return hasAssessmentActivity;
    }

    private hasAssessmentActivity(formId: string, userToken: IToken): boolean {
        const keyName = this.getKeyName(formId);
        const personId = localStorage.getItem(keyName);
        return !!personId && personId === userToken.personId;
    }

    private getKeyName(formId: string) {
        return formKeyPrefix + formId;
    }
}
