import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FormViewerApiService } from './form-viewer-api.service';
import { FormInfo, IFormInfo } from '../models/form-info.model';
import { MaSpinnerService } from '../shared/ma-spinner.service';

@Injectable({ providedIn: "root" })
export class FormInfoResolver implements Resolve<FormInfo> {
    formId: string;

    constructor(
        private formViewerApiSvc: FormViewerApiService,
        private spinnerSvc: MaSpinnerService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): FormInfo | Observable<FormInfo> | Promise<FormInfo> {
        this.formId = route.params.formId;
        this.spinnerSvc.show();
        return this.formViewerApiSvc.getFormInfo(this.formId).pipe(map(this.mapFormInfo.bind(this)));
    }

    private mapFormInfo(formInfo: IFormInfo) {
        return new FormInfo(formInfo, this.formViewerApiSvc.templatesUrl, this.formId)
    }
}