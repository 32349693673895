import { FormScoreValue } from '../form-score-value.model';
import { IQueryThread } from '../query.interface';
import { IToken } from '../token.interface';
import { CombinedRepliesFormQueryStateService } from './combined-replies-form-query-state.service';

export class EsFormQueryStateService extends CombinedRepliesFormQueryStateService {
    private isRaterAndReviewerScoresEqual: boolean = false;

    public get queryType(): string {
        return 'ES';
    }

    onFormScoreChanged(
        reviewerScoreValue: string,
        raterScoreValue: string,
        scoreName: string,
        formScoreValue: FormScoreValue,
        token: IToken,
        round: number): void {
        const allReviewRoundQueryThreads = this.reviewRoundQueryThreads;
        const currentReviewRound = this.getReviewRoundQueryThread(round);
        const isLastReviewRound = allReviewRoundQueryThreads.indexOf(currentReviewRound) === allReviewRoundQueryThreads.length - 1;

        if (!!formScoreValue.value && scoreName === formScoreValue.id && isLastReviewRound && !this.query.closeDate) {
            this.isRaterAndReviewerScoresEqual = reviewerScoreValue === formScoreValue.value;

            const text = this.isRaterAndReviewerScoresEqual
                ? `The rater agrees with the recommendation of the score ${reviewerScoreValue}.`
                : raterScoreValue === formScoreValue.value
                    ? 'The rater disagrees with the recommendation.'
                    : `The rater disagrees with the recommendation, and has changed the score to ${formScoreValue.value}.`;

            const queryThreadReplies = allReviewRoundQueryThreads[allReviewRoundQueryThreads.length - 1].replies;
            const raterComments = queryThreadReplies.filter(qt => !qt.isPersonReviewer);

            if (!!raterComments.length) {
                const relpyToDelete = raterComments[raterComments.length - 1];

                if (relpyToDelete.autoText === text) {
                    return;
                }

                this.deleteQueryThread(relpyToDelete);
            }

            const newThread: IQueryThread = this.getNewQueryThread(token, round, text, false, true);
            newThread.autoText = text;
            this.addQueryThreadToReview(newThread);
            this.query.isResolved = this.isRaterAndReviewerScoresEqual;
            this.queryStateUpdated.emit(this.query);
        }
    }

    public deleteQueryThread(queryThread: IQueryThread): void {
        this.deleteQueryThreadFromReview(queryThread);

        const currentReviewRound = this.getReviewRoundQueryThread(queryThread.roundNumber);
        const reviewRoundQueryThreads = this.reviewRoundQueryThreads[this.reviewRoundQueryThreads.length - 1].replies;
        const lastQueryThread = reviewRoundQueryThreads[reviewRoundQueryThreads.length - 1];

        const isQueryAutoResolved = !!lastQueryThread && lastQueryThread.isAuto && this.isRaterAndReviewerScoresEqual;
        const isQueryResolvedByComment = !!lastQueryThread && !lastQueryThread.isAuto;
        const isAutoThreadExists = currentReviewRound.replies.some(qt => qt.isAuto);

        this.query.isResolved = isQueryAutoResolved || (isQueryResolvedByComment && isAutoThreadExists);
        this.queryStateUpdated.emit(this.query);
    }
}
