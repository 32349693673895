import { Component, Inject } from '@angular/core';
import { BuildVersionModel } from 'src/app/models/buildVersion.model';
import { ApiInfoService } from 'src/app/services/api.info.service';

@Component({
    selector: 'about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss']
})
export class AboutComponent {
    private buildVersionInfo: BuildVersionModel;

    constructor(
        @Inject('ENV_SETTINGS') public baseUrl: any,
        private apiInfoService: ApiInfoService
    ) { }

    ngOnInit(): void {
        this.apiInfoService.getBuildVersion().subscribe((data: BuildVersionModel) => {
            this.buildVersionInfo = data;
        });
    }
    
    isBuildVersionLoaded(): boolean {
        return !!this.buildVersionInfo;
    }

    getHeaderBuildVersion(): string {
        return this.buildVersionInfo && this.buildVersionInfo.environmentName === 'Prod'
          ? this.buildVersionInfo.buildVersion
          : `${this.buildVersionInfo.environmentName}: ${this.buildVersionInfo.buildVersion}`;
      }
    
    getContentBuildVersion(): string {
        return this.buildVersionInfo.buildVersion;
    }

    getCurrentYear(): number {
        return this.buildVersionInfo.currentUtcServerTime.getUTCFullYear();
    }
}
