import { delay } from 'rxjs/operators';
import { MaMessagesService } from '../shared/ma-messages.service';
import { of, Subscription } from 'rxjs';
import { IToken } from '../models/token.interface';
import { CookieService } from './cookie.service';
import jwt_decode from 'jwt-decode';
import { BaseApiService } from './base-api.service';
import { Inject, Injectable } from '@angular/core';
import { IEnvironmentSettings } from '../shared/environment-settings.interface';
import { HttpClient } from '@angular/common/http';
import { UserActivityService } from './user-activity.service';

@Injectable({ providedIn: 'root' })
export class AuthService extends BaseApiService {
    private tokenSubscription = new Subscription();

    constructor(
        @Inject('ENV_SETTINGS') environmentSettings: IEnvironmentSettings,
        protected http: HttpClient,
        protected cookieSvc: CookieService,
        protected messagesSvc: MaMessagesService,
        userActivityService: UserActivityService) {
        super(
            http,
            environmentSettings,
            cookieSvc,
            messagesSvc,
            userActivityService);
    }

    public logout(): void {
        this.userActivityService.clearAssessmentActivity();
        const redirectUrl = encodeURIComponent(window.location.href);
        window.location.href = `${this.environmentSettings.authorizationUri}${this.authorizationApiUrl}/logout/${document.location.host}?redirectUrl=${redirectUrl}`;
    }

    public getAuthorizationTokenValues(): IToken {
        const token = this.cookieSvc.get(this.authorizationCookieName);
        if (token) {
            const decodedToken = jwt_decode<IToken>(token);
            if (!this.isTokenExpired(decodedToken.exp)) {
                this.expirationCounter(decodedToken.exp * 1000 - Date.now());
                return decodedToken;
            } else {
                this.tokenSubscription.unsubscribe();
                this.logout();
            }
        }

        return null;
    }

    private isTokenExpired(validTo: number): boolean {
        return Math.floor(Date.now() / 1000) >= validTo;
    }

    private expirationCounter(timeout: number): void {
        this.tokenSubscription.unsubscribe();
        this.tokenSubscription = of(null).pipe(delay(timeout)).subscribe((_) => {
            this.getAuthorizationTokenValues();
        });
    }
}
