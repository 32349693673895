<div [ngClass]="{
        expanded: isExpanded,
        expandable: canBeExpanded()
    }" class="comment-block">
    <header class="comment-header">
        <button type="button" class="toggler" title="Toggle comment content" (click)="switchExpandMode()"></button>
    
        <div class="box-icon">
            <span class="{{markerElementCssClassName}}" [ngClass]="isResolved ? '' : notResolvedQueryCssClassName">
                <span class="text">{{ markerText }}</span>
            </span>
        </div>
        <div class="meta-info-holder">
            <strong class="name">
                {{ getAuthorName(queryReviewRound.root) }}
            </strong>
            <time *ngIf="queryReviewRound.root.timestamp" class="published" [dateTime]="getLocalTimestamp(queryReviewRound.root) | date: dateFormat">
                {{ getLocalTimestamp(queryReviewRound.root) | date: dateTimeFormat | uppercase }}
            </time>
        </div>
    
        <div class="comment-actions-box">
            <div class="comment-input-frame">
                <p class="query-text" *ngIf="!queryReviewRound.root.isEditMode; else editInitialThreadBlock">
                    {{ getDisplayText(queryReviewRound.root) }}
                </p>
                <ng-template #editInitialThreadBlock>
                    <query-thread-form
                        [text]="getDisplayText(queryReviewRound.root)"
                        [isAcknowledged]="queryReviewRound.root.isAcknowledged"
                        [canAcknowledgeField]="canAcknowledge(queryReviewRound.root)"
                        [queryThread]="queryReviewRound.root"
                        [resetChanges]="resetChanges"
                        (formChanged)="onEditFormChanged(queryReviewRound.root, $event)"
                        [isRequired]="isResolutionRequired()"
                        [isTextBoxValueRequired]="isReplyTextRequired"
                        (formSubmitted)="saveQueryThreadChanges(queryReviewRound.root, $event)"></query-thread-form>
                </ng-template>
            </div>
            <div *ngIf="canManageRootQueryThread()" class="actions-box">
                <button class="btn btn-icon"
                        type="button"
                        title="Delete"
                        *ngIf="canDeleteRootQueryThread(queryReviewRound.root)"
                        [disabled]="!isAuthor(queryReviewRound.root)"
                        (click)="deleteQuery()">
                    <i class="icon-delete"></i>
                </button>
                <button class="btn btn-icon"
                        type="button"
                        title="Edit"
                        *ngIf="!queryReviewRound.root.isEditMode"
                        [disabled]="!isAuthor(queryReviewRound.root)"
                        (click)="switchEditMode(queryReviewRound.root)">
                    <i class="icon-edit"></i>
                </button>
                <button class="btn btn-icon"
                        type="button"
                        title="Cancel"
                        *ngIf="queryReviewRound.root.isEditMode && !!getDisplayText(queryReviewRound.root)"
                        (click)="switchEditMode(queryReviewRound.root)">
                    <i class="icon-close"></i>
                </button>
                <button class="btn btn-icon btn-success"
                        type="button"
                        title="Done"
                        *ngIf="queryReviewRound.root.isEditMode"
                        [disabled]="!isEnabledSaveEditForm(queryReviewRound.root)"
                        (click)="submitQueryThread(queryReviewRound.root)">
                    <i class="icon-done"></i>
                </button>
            </div>
            <span class="badge-text badge-small badge-light" *ngIf="isClosed">Closed</span>
        </div>
    </header>
    
    <div class="comment-content" [@collapse]="!isExpanded">
        <ul class="comments-list">
            <li *ngFor="let notInitialQueryThread of queryReviewRound.replies">
                <div class="comment-frame">
                    <div class="meta-info-holder">
                        <strong class="name">
                            {{ getAuthorName(notInitialQueryThread) }}
                        </strong>
                        <time *ngIf="notInitialQueryThread.timestamp" class="published" [dateTime]="getLocalTimestamp(notInitialQueryThread) | date: dateFormat">
                            {{ getLocalTimestamp(notInitialQueryThread) | date: dateTimeFormat | uppercase }}
                        </time>
                    </div>
    
                    <div class="comment-actions-box" [ngClass]="{'align-bottom': notInitialQueryThread.isEditMode}">
                        <div class="comment-input-frame">
                            <div *ngIf="!notInitialQueryThread.isEditMode; else editThreadBlock">
                                <p *ngIf="isClosedBySystem(notInitialQueryThread); else notSystemClose">
                                    <strong *ngIf="!!getDisplayText(notInitialQueryThread)">
                                        {{ getDisplayText(notInitialQueryThread) }}
                                    </strong>
                                </p>
                                <ng-template #notSystemClose>
                                    <p *ngIf="!!getDisplayText(notInitialQueryThread)" class="query-text">
                                        {{ getDisplayText(notInitialQueryThread) }}
                                    </p>
                                </ng-template>
                                <p *ngIf="isClosedNotBySystem(notInitialQueryThread) && canBeClosed">
                                    <strong>Closed by expert reviewer.</strong>
                                </p>
                                <p *ngIf="notInitialQueryThread.isAcknowledged">
                                    <strong>Acknowledged by rater.</strong>
                                </p>
                            </div>
                            <ng-template #editThreadBlock>
                                <p *ngIf="!!getReadOnlyText(notInitialQueryThread)">
                                    {{ getReadOnlyText(notInitialQueryThread) }}
                                </p>
                                <query-thread-form
                                    [text]="getEditableText(notInitialQueryThread)"
                                    [isAcknowledged]="notInitialQueryThread.isAcknowledged"
                                    [canAcknowledgeField]="canAcknowledge(notInitialQueryThread)"
                                    [queryThread]="notInitialQueryThread"
                                    [resetChanges]="resetChanges"
                                    [isTextBoxValueRequired]="isReplyTextRequired"
                                    (formChanged)="onEditFormChanged(notInitialQueryThread, $event)"
                                    (formSubmitted)="saveQueryThreadChanges(notInitialQueryThread, $event)"></query-thread-form>
                            </ng-template>
                        </div>
                        <div *ngIf="!isViewMode && canManageQueryThread(notInitialQueryThread)" class="actions-box">
                            <button class="btn btn-icon"
                                    type="button"
                                    title="Delete"
                                    *ngIf="!notInitialQueryThread.isEditMode && canDeleteQueryThread(notInitialQueryThread)"
                                    [disabled]="!isAuthor(notInitialQueryThread)"
                                    (click)="deleteQueryThread(notInitialQueryThread)">
                                <i class="icon-delete"></i>
                            </button>
                            <button class="btn btn-icon"
                                    type="button"
                                    title="Edit"
                                    *ngIf="!notInitialQueryThread.isEditMode"
                                    [disabled]="!isAuthor(notInitialQueryThread)"
                                    (click)="switchEditMode(notInitialQueryThread)">
                                <i class="icon-edit"></i>
                            </button>
                            <button class="btn btn-icon"
                                    type="button"
                                    title="Cancel"
                                    *ngIf="notInitialQueryThread.isEditMode"
                                    (click)="switchEditMode(notInitialQueryThread)">
                                <i class="icon-close"></i>
                            </button>
                            <button class="btn btn-icon btn-success"
                                    type="button"
                                    title="Done"
                                    *ngIf="notInitialQueryThread.isEditMode"
                                    [disabled]="!isEnabledSaveEditForm(notInitialQueryThread)"
                                    (click)="submitQueryThread(notInitialQueryThread)">
                                <i class="icon-done"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
        <div *ngIf="!isViewMode && canAddReply" class="comment-frame">
            <div class="comment-actions-box">
                <div class="comment-input-frame">
                    <query-thread-form
                        [canAcknowledgeField]="canAcknowledge(null)"
                        [isRequired]="isResolutionRequired()"
                        [resetChanges]="resetChanges"
                        [isTextBoxValueRequired]="isReplyTextRequired"
                        (formChanged)="onAddFormChanged($event)"
                        (formSubmitted)="addNewQueryThread($event)"></query-thread-form>
                </div>
                <div class="actions-box">
                    <button class="btn btn-icon btn-success"
                            type="button"
                            title="Done"
                            [disabled]="!isEnabledSaveAddForm()"
                            (click)="submitQueryThreadReply()">
                        <i class="icon-done"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>