import { Injectable } from '@angular/core';
import { IQueryReviewRound, IQueryThread } from '../query.interface';
import { BaseViewFormQueryStateService } from './base-view-form-query-state-service';

@Injectable({
  providedIn: 'root'
})
export class CombinedRepliesViewFormQueryStateService extends BaseViewFormQueryStateService {

  isQueryResolutionRequired(): boolean {
    const allReviewRoundQueryThreads = this.formStateService.reviewRoundQueryThreads;
    const reviewRoundQueryThreads = allReviewRoundQueryThreads[allReviewRoundQueryThreads.length - 1].replies;

    const lastQueryThread = reviewRoundQueryThreads[reviewRoundQueryThreads.length - 1];

    return !this.formStateService.query.isResolved
      && !!lastQueryThread
      && (lastQueryThread.isAuto || lastQueryThread.isPersonReviewer);
  }

  public canAddReply(queryReviewRound: IQueryReviewRound, currentRound: number): boolean {
    if (this.isClosed(queryReviewRound)) {
        return false;
    }

    const hasOnlyReviewerComments = queryReviewRound.replies.every(qt => qt.isPersonReviewer);
    const hasManualRaterComment = queryReviewRound.replies.some(qt => !qt.isPersonReviewer && !!qt.manualText);
    return (hasOnlyReviewerComments || !hasManualRaterComment)
        && queryReviewRound.round === currentRound;
  }

  canDeleteQueryThread(queryThread: IQueryThread, currentRound: number): boolean {
    return false;
  }

  canManageQueryThread(queryThread: IQueryThread, currentRound: number): boolean {
    return !queryThread.isPersonReviewer
      && !!queryThread.manualText
      && queryThread.roundNumber === currentRound;
}

  public getDisplayText(queryThread: IQueryThread): string {
    const isRootReply = this.formStateService.query.reviewRoundQueryThreads
        .some(reviewRound => reviewRound.root === queryThread);

    return isRootReply
      ? queryThread.text
      : queryThread.extendedText;
  }

  public getReadOnlyText(queryThread: IQueryThread): string {
    return queryThread.autoText;
  }

  public getEditableText(queryThread: IQueryThread): string {
    return queryThread.manualText;
  }
}
