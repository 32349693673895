<ng-container *ngIf="!!sectionsItems.length">
    <header class="review-headings">
        <strong class="head-section">Section</strong>
        <strong class="head-rater">Сurrent Score</strong>
        <strong class="head-recommended">Updated Score</strong>
    </header>
    <div class="review-items-container">
        <div class="review-item" *ngFor="let section of sectionsItems; index as index" [ngClass]="{'expanded': section.isExpanded}">
            <header class="item-panel" (click)="pickFormSection(section.sectionStartPage, section)">
                <button type="button" class="toggler" *ngIf="hasSectionQueries(section)" (click)="toggleHeader(section)" title="Toggle section"></button>

                <h2><strong *ngIf="section.sectionNumber">{{section.sectionNumber}}.</strong> {{section.name}}</h2>

                <div class="item-col col-rater">
                    <div class="num-box"
                         *ngIf="isRaterScoresVisible(section) && (section.sectionTypeId === sectionWithRawScoreId
                                || section.sectionTypeId === sectionWithRawAndRecommendedScoreId)">
                        <em class="main-elem">
                            {{ section.rawScoreValue }}
                        </em>
                    </div>
                    <div class="num-box" *ngIf="isRaterScoresVisible(section) && section.sectionTypeId === sectionWithRawAndConvertedScoreId">
                        <em class="main-elem">
                            {{ section.convertedScoreValue }}
                        </em>
                        <em class="additional-elem">{{ section.rawScoreValue }}</em>
                    </div>
                    <app-form-query-markers
                        [isTableMode]="true"
                        [currentRound]="currentRound"
                        [updateMarkers]="getSectionUpdateObservable(section)">
                    </app-form-query-markers>
                </div>

                <div class="item-col col-recommended" *ngIf="areUpdatedScoresVisible(section)">
                    <div class="num-box" *ngIf="section.sectionTypeId === sectionWithRawScoreId">
                        <em class="main-elem"
                            [ngClass]="section.updatedScoreCss">
                            {{ section.updatedRawScoreValue }}
                        </em>
                    </div>
                    <div class="num-box" *ngIf="section.sectionTypeId === sectionWithRawAndConvertedScoreId">
                        <em class="main-elem"
                            [ngClass]="section.updatedScoreCss">
                            {{ section.updatedConvertedScoreValue }}
                        </em>
                        <em class="additional-elem">{{ section.updatedRawScoreValue }}</em>
                    </div>
                    <div class="num-box" *ngIf="section.sectionTypeId === sectionWithRawAndRecommendedScoreId">
                        <em class="main-elem">
                            {{ section.updatedRawScoreValue }}
                        </em>
                    </div>
                </div>

                <button *ngIf="!isViewMode && !!getPrimaryScoreId(section)"
                    class="btn btn-icon btn-primary-light add-comment"
                    type="button"
                    title="Add comment"
                    [disabled]="!canAddNewComment(section)"
                    (click)="addComment(section)">
                    <i class="icon-add-comment"></i>
                </button>
            </header>
            <div class="comments-container" [@collapse]="!section.isExpanded">
                <div *ngFor="let sectionQueriesByRound of getSectionQueriesByRounds(section)" class="comments-block">
                    <form-query-review-round
                        [sectionQueriesByRound]="sectionQueriesByRound"
                        [isViewMode]="isViewMode"
                        [currentRound]="currentRound"
                        (queryUpdated)="updateQueries()"
                        (queryDeleted)="deleteQuery($event)"
                        [reviewerScoreValue]="section.reviewerRawScoreValue"
                        [raterScoreValue]="section.rawScoreValue"
                        [onFieldValueChanged]="fieldValueChangedSubject.asObservable()"
                        [onFieldValueLoaded]="fieldValueLoadedSubject.asObservable()"
                        [changeableScoreName]="section.rawScoreName"
                        [resetChanges]="getSectionResetObservable(section)"
                        [isExpanded]="sectionQueriesByRound.round === currentRound"
                        [sectionUpdated$]="getSectionUpdateObservable(section)">
                    </form-query-review-round>
                </div>
            </div>
        </div>
    </div>
</ng-container>