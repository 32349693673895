import { WebBundleMode } from './web-bundle-commands/web-bundle-input-commands';

export class FormInfo {
    formState: string;
    webBundleUrl: string;
    webBundleMode: WebBundleMode;
    formId: string;

    constructor(form: IFormInfo, templatesUrl: string, formId: string) {
        this.formState = form.formState;
        this.webBundleUrl = `${templatesUrl}/${form.cultureFormTemplateVersionId}/form.html`;
        this.webBundleMode = form.isEdit ? WebBundleMode.Edit : WebBundleMode.View;
        this.formId = formId;
    }
}

export interface IFormInfo {
    cultureFormTemplateVersionId: string;
    formState: string;
    isEdit: boolean;
}