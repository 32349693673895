import { Component, Inject, Input, OnInit } from '@angular/core';
import { BuildVersionModel } from 'src/app/models/buildVersion.model';
import { ApiInfoService } from 'src/app/services/api.info.service';
import { AuthService } from 'src/app/services/auth.service';
import { FormViewerApiService } from 'src/app/services/form-viewer-api.service';

@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
  @Input() isHeaderActionsVisible: boolean;

  public dropdownIsOpened: boolean = false;
  public firstName: string;
  public lastName: string;
  public toggle : boolean = false;
  public buildVersion : BuildVersionModel;
  public buildVersionView: string;

  constructor(
    @Inject('ENV_SETTINGS') public baseUrl: any,
    private authService: AuthService,
    private apiinfoService:ApiInfoService
  ) { }

  ngOnInit(): void {
    const tokenValues = this.authService.getAuthorizationTokenValues();
    if(tokenValues) {
    this.firstName = tokenValues.firstname;
    this.lastName = tokenValues.lastname;
    }
    this.apiinfoService.getBuildVersion().subscribe((data:BuildVersionModel)=>{
      this.buildVersion = data;
      if(!(this.buildVersion.environmentName === 'Prod')){        
        this.buildVersionView = data.environmentName + ': ' + data.buildVersion;
      }
    });
  }

  public toggleOpenState() {
    this.dropdownIsOpened = !this.dropdownIsOpened;
  }

  public closeMenu() {
    this.dropdownIsOpened = false;
  }

  public logout(): void {
    this.authService.logout();
  }
}
