import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DateTimeService } from '../shared/date-time.service';

export const serverTimeHeaderName: string = 'server-time';

@Injectable()
export class ServerDateTimeSyncInterceptor implements HttpInterceptor {
    constructor(private dateTimeService: DateTimeService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse && event.headers.has(serverTimeHeaderName)) {
                    const serverDateValue = event.headers.get(serverTimeHeaderName);
                    const serverDate = this.dateTimeService.convertToDate(serverDateValue);
                    const serverDateUtc = this.dateTimeService.toUtcDate(serverDate);

                    this.dateTimeService.syncServerDateTime(serverDateUtc);
                }
            }));
    }
}
