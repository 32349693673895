import { Injectable } from '@angular/core';
import { DiscrepancyType, FormQueryType, IQuery } from '../models/query.interface';

@Injectable({providedIn: 'root'})
export class QueryOrderService {
    // Default ordering: FS, ES, SS, XC, PT, Comments from rater, Comment manual from reviewer, Score change suggestion.
    private discrepancyOrderMap = new Map<string, number>([
        [DiscrepancyType.FsScore, 0],
        [DiscrepancyType.EdssStep, 1],
        [DiscrepancyType.SubScores, 2],
        [DiscrepancyType.CrossCheck, 3],
        [DiscrepancyType.PtMarkers, 4],
    ]);

    private raterCommentOrderIndex = 5;

    private reviewerCommentOrderMap = new Map<boolean, number>([
        [false, 6], // Comment manual from reviewer.
        [true, 7],  // Score change suggestion.
    ]);

    public supportedQueryTypesCount: number;

    constructor() {
        this.supportedQueryTypesCount =
            this.discrepancyOrderMap.size
            + this.reviewerCommentOrderMap.size
            + 1; // rater comment query type;
    }

    public getQueryOrder(query: IQuery): number {
        const notSupportedIndex = -1;
        if (!query) {
            return notSupportedIndex;
        }

        switch (query.formQueryTypeId) {
            case FormQueryType.RaterComment:
                return this.raterCommentOrderIndex;
            case FormQueryType.ReviewerComment:
                return this.reviewerCommentOrderMap.get(query.isAuto);
            case FormQueryType.Discrepancy:
                return this.discrepancyOrderMap.has(query.formDiscrepancyTypeId)
                    ? this.discrepancyOrderMap.get(query.formDiscrepancyTypeId)
                    : notSupportedIndex;
            default: return notSupportedIndex;
        }
    }

    public sortByQueries<T>(collection: T[], getQueryFromObj: (obj: T) => IQuery) {
        collection.sort((objA, objB) => {
            const queryA = getQueryFromObj(objA);
            const queryB = getQueryFromObj(objB);
            const objOrderA = this.getQueryOrder(queryA);
            const objOrderB = this.getQueryOrder(queryB);

            let orderComparison = objOrderA - objOrderB;
            if (orderComparison === 0) {
                orderComparison = queryA.roundNumber - queryB.roundNumber;
            }
            return orderComparison < 0 ? -1 : 1;
        });
    }
}
