import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { PLATFORM_ID, Injectable, Inject } from '@angular/core';

@Injectable({ providedIn: "root" })
export class CookieService {
    private documentIsAccessible: boolean;
    constructor(
        @Inject(DOCUMENT) private document: Document,
        // Get the `PLATFORM_ID` so we can check if we're in a browser.
        @Inject(PLATFORM_ID) platformId: Object) {
        this.documentIsAccessible = isPlatformBrowser(platformId);
    }
    /**
     * @param name Cookie name
     * @returns boolean - whether cookie with specified name exists
     */
    check(name: string) {
        if (!this.documentIsAccessible) {
            return false;
        }
        name = encodeURIComponent(name);
        const regExp = this.getCookieRegExp(name);
        const exists = regExp.test(this.document.cookie);
        return exists;
    }
    /**
     * @param name Cookie name
     * @returns property value
     */
    get(name: string) {
        if (this.documentIsAccessible && this.check(name)) {
            name = encodeURIComponent(name);
            const regExp = this.getCookieRegExp(name);
            const result = regExp.exec(this.document.cookie);
            return this.safeDecodeURIComponent(result[1]);
        }
        else {
            return '';
        }
    }
    /**
     * @returns all the cookies in json
     */
    getAll(): { [cookieName: string]: string } {
        if (!this.documentIsAccessible) {
            return {};
        }

        let cookies = {};
        if (document.cookie && this.document.cookie.length) {
            document.cookie.split(';').forEach((currentCookie) => {
                const [cookieName, cookieValue] = currentCookie.split('=');
                const decCookieName = this.safeDecodeURIComponent(cookieName.replace(/^ /, ''));
                const decCookieValue = this.safeDecodeURIComponent(cookieValue);
                cookies[decCookieName] = decCookieValue;
            });
        }

        return cookies;
    }

    /**
    * @param name Cookie name
    */
    public deleteCookie(name): void {
        this.set(name, '', {expires: -1});
    }

    set(name: string, value: any, expiresOrOptions: IExpiresOrOptions): void;
    set(name: string, value: any, expiresOrOptions: number | Date | IExpiresOrOptions,
        path?: string,
        domain?: string,
        secure?: boolean,
        sameSite?: string): void {
        if (!this.documentIsAccessible) {
            return;
        }

        if (typeof expiresOrOptions === "number" || expiresOrOptions instanceof Date
            || typeof path !== "undefined"
            || typeof domain !== "undefined"
            || typeof secure !== "undefined"
            || typeof sameSite !== "undefined") {
            const optionsBody: IExpiresOrOptions = {
                expires: expiresOrOptions as numberOrDate,
                path,
                domain,
                secure,
                sameSite: sameSite || 'Lax'
            };
            this.set(name, value, optionsBody);
            return;
        }

        let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)};`;
        const options: IExpiresOrOptions = expiresOrOptions || {};

        if (options.expires) {
            if (typeof options.expires === "number") {
                const dateExpires = new Date(new Date().getTime() + options.expires * 1000 * 60 * 60 * 24);
                cookieString += `expires=${dateExpires.toUTCString()};`;
            }
            else {
                cookieString += `expires=${options.expires.toUTCString()};`;
            }
        }

        if (options.path) {
            cookieString += `path=${options.path};`;
        }

        if (options.domain) {
            cookieString += `domain=${options.domain};`;
        }

        if (options.secure === false && options.sameSite === "None") {
            options.secure = true;
            console.warn(`Cookie ${name} was forced with secure flag because sameSite = None.`);
        }

        if (options.secure) {
            cookieString += 'secure;';
        }

        if (!options.sameSite) {
            options.sameSite = 'Lax';
        }

        cookieString += `sameSite=${options.sameSite};`;
        this.document.cookie = cookieString;
    }

    /**
     * @param name   Cookie name
     * @param path   Cookie path
     * @param domain Cookie domain
     */
    delete(name: string, path: string, domain: string, secure: boolean, sameSite = 'Lax') {
        if (!this.documentIsAccessible) {
            return;
        }

        const expiresDate = new Date('Thu, 01 Jan 1970 00:00:01 GMT');
        this.set(name, '', { expires: expiresDate, path, domain, secure, sameSite });
    }

    /**
     * @param path   Cookie path
     * @param domain Cookie domain
     */
    deleteAll(path: string, domain: string, secure: boolean, sameSite = 'Lax') {
        if (!this.documentIsAccessible) {
            return;
        }

        const cookies = this.getAll();
        for (const cookieName in cookies) {
            if (cookies.hasOwnProperty(cookieName)) {
                this.delete(cookieName, path, domain, secure, sameSite);
            }
        }
    }

    /**
     * @param name Cookie name
     * @returns property RegExp
     */
    private getCookieRegExp(name: string) {
        const escapedName = name.replace(/([\[\]\{\}\(\)\|\=\;\+\?\,\.\*\^\$])/gi, '\\$1');
        return new RegExp('(?:^' + escapedName + '|;\\s*' + escapedName + ')=(.*?)(?:;|$)', 'g');
    }

    private safeDecodeURIComponent(encodedURIComponent: string) {
        try {
            return decodeURIComponent(encodedURIComponent);
        }
        catch (ex) {
            // probably it is not uri encoded. return as is
            return encodedURIComponent;
        }
    }
}

interface IExpiresOrOptions {
    expires?: numberOrDate;
    path?: string;
    domain?: string;
    secure?: boolean;
    sameSite?: string;
}

type numberOrDate = number | Date;