import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DateTimeService {
    private serverDeltaTime: number = 0;

    public getCurrentDate(): Date {
        return new Date();
    }

    public getCurrentDateUtc(): Date {
        return this.toUtcDate(this.getCurrentDate());
    }

    public getServerCurrentDateUtc(): Date {
        const clientUtcNow = this.getCurrentDateUtc();
        const serverUtcNowMilliseconds = clientUtcNow.getTime() + this.serverDeltaTime;
        return new Date(serverUtcNowMilliseconds);
    }

    public toUtcDate(date: Date): Date {
        return !!date
            ? new Date(Date.UTC(
                date.getUTCFullYear(),
                date.getUTCMonth(),
                date.getUTCDate(),
                date.getUTCHours(),
                date.getUTCMinutes(),
                date.getUTCSeconds()))
            : null;
    }

    public toLocalDate(date: Date): Date {
        return !!date
            ? new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate(),
                date.getHours(),
                date.getMinutes(),
                date.getSeconds())
            : null;
    }

    public isIsoDate(isoDate: any): boolean {
        const isoDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?Z?$/;

        return isoDate === null || isoDate === undefined || typeof isoDate !== 'string'
            ? false
            : isoDateRegex.test(isoDate);
      }

    public convertToDate(date: any): Date {
        return date instanceof Date
            ? date
            : new Date(date.toString());
    }

    public syncServerDateTime(serverUtcDate: Date): void {
        const clientUtcDate = this.getCurrentDateUtc();
        this.serverDeltaTime = serverUtcDate.getTime() - clientUtcDate.getTime();
    }
}
