import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first, map, switchMap, takeUntil } from 'rxjs/operators';
import { FormInfo } from 'src/app/models/form-info.model';
import { IQuery, IQueryIndicator } from 'src/app/models/query.interface';
import { FormDetails, reviewStatus, subjectVisitStatus } from 'src/app/models/form-details.model';
import { WebBundleMode } from 'src/app/models/web-bundle-commands/web-bundle-input-commands';
import { FormViewerApiService } from 'src/app/services/form-viewer-api.service';
import { ReviewSectionApiService } from 'src/app/services/review-section-api.service';
import { ComponentCanDeactivate } from '../../guards/pending-changes-guard.guard';
import { ChangesDetectorService } from '../../services/changes-detector.service';
import { of, Subject, zip } from 'rxjs';
import { MaSpinnerService } from 'src/app/shared/ma-spinner.service';
import { AssessmentApiService } from 'src/app/services/assessment-api.service';
import { QueriesService } from 'src/app/services/queries.service';
import { WebBundleStateService } from 'src/app/services/web-bundle-state.service';
import { SubmitFormComponent } from 'src/app/shared/components/submit-form/submit-form.component';
import { ConfirmationComponent } from 'src/app/shared/components/confirmation/confirmation.component';
import { ConfirmationSettings } from 'src/app/shared/components/confirmation/confirmation-settings';
import { SubmitService } from 'src/app/services/submit.service';
import { MultipleActiveSessionService } from 'src/app/services/multiple-active-session.service';
import { DialogService } from '../../services/dialog.service';
import { MaDialogsService } from 'ma-dialogs';


@Component({
    selector: 'form-reviewer',
    templateUrl: './form-reviewer.component.html',
    styleUrls: ['./form-reviewer.component.scss']
})
export class FormReviewerComponent implements OnInit, ComponentCanDeactivate, OnDestroy {
    backToPortalUrl: string;
    formInfo: FormInfo;
    queries: IQuery[] = [];
    formDetails: FormDetails;
    formId: string;
    isDetailsPopupOpened = false;
    isHelpPopupOpened = false;
    isUnsavedChangesDetected = false;
    formErrorCount = 0;

    queryIndicators: IQueryIndicator[];

    public webMode: WebBundleMode;

    public isSubmitted: boolean = false;
    private destroy$: Subject<void> = new Subject();

    constructor(
        private route: ActivatedRoute,
        private formViewService: FormViewerApiService,
        private reviewSectionApiService: ReviewSectionApiService,
        private dialogService: DialogService,
        private madialogService: MaDialogsService,
        private changesDetectorService: ChangesDetectorService,
        private assessmentApiService: AssessmentApiService,
        private spinnerService: MaSpinnerService,
        private queriesService: QueriesService,
        private webBundleService: WebBundleStateService,
        private submitService: SubmitService,
        private multipleActiveSessionService: MultipleActiveSessionService,
    ) { }

    canDeactivate(): boolean {
        return this.isUnsavedChangesDetected;
    }

    ngOnInit() {
        const spinner = this.spinnerService.show();

        this.multipleActiveSessionService.multipleSessionDetected$
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.dialogService
                .openMultipleSessionDetectedDialog()
                .overrideYes(() => location.reload()));

        this.submitService.submitResult$
            .pipe(takeUntil(this.destroy$))
            .subscribe(isSubmitted => this.isSubmitted = isSubmitted);

        this.route.data
            .pipe(
                first(),
                switchMap(data => {
                    this.formInfo = data['formInfo'];
                    this.webMode = this.formInfo.webBundleMode;
                    this.formId = this.formInfo.formId;

                    const formDetails$ = this.formViewService.getFormDetails(this.formId);
                    const queries$ = this.reviewSectionApiService.getQueries(this.formId);
                    return zip(formDetails$, queries$).pipe(
                        map(([formDetails, queries]) => ({ formDetails, queries }))
                    );
                }),
                switchMap(data => {
                    this.formDetails = new FormDetails(data.formDetails);
                    this.queriesService.initQueryReviewRoundQueryThreads(data.queries, Number(this.formDetails.round));
                    this.queries = data.queries;
                    this.queryIndicators = this.queries
                        .filter(query => !!query.queryIndicators && query.queryIndicators.length)
                        .reduce((query, nextQuery) => [...query, ...nextQuery.queryIndicators], []);

                    return this.webMode === WebBundleMode.View
                        ? of(null)
                        : this.reviewSectionApiService.startUserActivitySession(this.formId);
                }),
                takeUntil(this.destroy$))
            .subscribe(() => {
                spinner.hide();
            }, error => {
                spinner.hide();
                throw new Error(error);
            });

        this.changesDetectorService
            .pendingChangesEventListner()
            .pipe(takeUntil(this.destroy$))
            .subscribe(info => this.isUnsavedChangesDetected = info);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public isViewMode(): boolean {
        return this.webMode === WebBundleMode.View;
    }

    public isEditMode(): boolean {
        return this.webMode === WebBundleMode.Edit;
    }

    public submitReview(): void {
        if (this.isUnsavedChangesDetected) {
            const dialog = this.dialogService.openConfirmPendingChangesDialog();
            dialog.overrideYes(() => {
                this.submitHandler();
                dialog.close(true);
            });
        } else {
            this.submitHandler();
        }
    }

    private submitHandler() {
        const spinnerRef = this.spinnerService.show();

        this.webBundleService.getFormErrorCount().pipe(first()).subscribe(errorCount => {
            if (errorCount > 0) {
                this.webBundleService.canSubmitWithErrors().pipe(first()).subscribe(canSubmitInvalidForm => {
                    if (!canSubmitInvalidForm) {
                        this.openConfirmCannotSubmitWithErrorsDialog();
                    } else {
                        this.openSubmitDialog();
                    }
                    spinnerRef.hide();
                });
            } else {
                this.openSubmitDialog();
                spinnerRef.hide();
            }
        })
    }

    public canUserEditForm(): boolean {
        return this.formDetails && this.formDetails.canConductAssessment && !this.formDetails.isSubmitted;
    }

    public canOpenEditMode(): boolean {
        return this.isViewMode() && this.isReviewStatusAwaitingRater();
    }

    public editAssessment(): void {
        const spinner = this.spinnerService.show();
        this.assessmentApiService
            .editAssessment(this.formId)
            .subscribe(
                success => {
                    if (success) {
                        window.location.reload();
                    }
                },
                error => {
                    spinner.hide();
                    throw new Error(error);
                },
                () => spinner.hide());
    }

    public areQueriesResolved(): boolean {
        return !this.queries.some(q => q.isResolved === false);
    }

    public toggleDetails(): void {
        this.isDetailsPopupOpened = !this.isDetailsPopupOpened;
    }

    public closeDetails(): void {
        this.isDetailsPopupOpened = false;
    }

    public toggleHelpWindow(): void {
        this.isHelpPopupOpened = !this.isHelpPopupOpened;
    }

    public closeHelpWindow(): void {
        this.isHelpPopupOpened = false;
    }

    public openSubmitDialog(): void {
        const settings = {
            width: '455px',
            title: 'Submit',
            noFooter: true,
            data: {
                formId: this.formId,
                queries: this.queries,
                noFooter: false
            }
        };

        this.madialogService
            .openDialog(SubmitFormComponent, settings)
            .afterClosed()
            .pipe(takeUntil(this.destroy$))
            .subscribe(_ => {
                if (this.isSubmitted) {
                    const spinner = this.spinnerService.show();
                    window.location.reload();
                    spinner.hide();
                }
            });
    }

    public openConfirmCannotSubmitWithErrorsDialog() {
        const settings: Partial<ConfirmationSettings> = {
            title: 'Submit',
            hasYesButton: false,
            width: '455px',
            text: `<div class="text-height-limiter"> There are unanswered required fields or invalid scores in the form that are preventing this form from being submitted. <br> <br>
                You must return to the form and make corrections before trying to submit.</div>`,
        };
        this.madialogService.openDialog(ConfirmationComponent, settings);
    }

    public isReviewStatusAwaitingRater(): boolean {
        return this.formDetails.reviewStatus === reviewStatus.AwaitingRater || this.formDetails.reviewStatus === null;
    }

    public isSubjectVisitInProgress(): boolean {
        return this.formDetails.subjectVisitStatus === subjectVisitStatus.InProgress;
    }

    public isDataLocked(): boolean {
        return this.formDetails.isDataLocked;
    }

    public isUserQualified(): boolean {
        return this.formDetails.canConductAssessment;
    }

    public isEditingDisabled(): boolean{
        return !this.isUserQualified()
            || this.isSubjectVisitInProgress()
            || this.isDataLocked()
            || !this.formDetails.isRaterAssignedToReview;
    }
}
