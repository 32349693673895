import { Injectable } from '@angular/core';
import { concat } from 'rxjs';
import { FormQueryStateServiceFactory } from '../models/form-query-state/form-query-state-service-factory';
import { IQuery, IRoundQueries } from '../models/query.interface';

@Injectable({
    providedIn: 'root'
})
export class QueriesService {
    constructor(private formQueryStateServiceFactory: FormQueryStateServiceFactory) { }

    public initQueryReviewRoundQueryThreads(queries: IQuery[], roundNumber: number): void {
        queries.forEach(q => {
            if (!q.reviewRoundQueryThreads || !q.reviewRoundQueryThreads.length) {
                const formQueryStateService = this.formQueryStateServiceFactory.getQueryStateService(q, roundNumber);
                q.reviewRoundQueryThreads = formQueryStateService.reviewRoundQueryThreads;
            }
        });
    }

    public getRoundsQueries(queries: IQuery[]): IRoundQueries[] {
        const roundsQueries = [];
        if (!!queries.length) {
            const maxRoundNumber = queries
                .reduce((query, nextQuery) => [ ...query, ...nextQuery.reviewRoundQueryThreads ], [])
                .sort((a, b) => b.round - a.round)[0]
                .round;

            for (let i = 0; i < maxRoundNumber; i++) {
                const roundQueries = queries
                    .filter(q => q.reviewRoundQueryThreads
                    .some(rrqt => rrqt.round === i + 1));
                this.addRoundQueries(roundsQueries, roundQueries, i + 1);
            }
        }

        return roundsQueries.sort(rd => rd.round);
    }

    public updateRoundQueries(currentRoundQueries: IRoundQueries[], queries: IQuery[]): IRoundQueries[] {
        const generatedRoundQueries = this.getRoundsQueries(queries);

        const existRoundNumbers = currentRoundQueries.map(roundQuery => roundQuery.round);

        const addedRoundQueries = generatedRoundQueries.filter(roundQuery => !existRoundNumbers.includes(roundQuery.round));
        const updatedRoundQueries = generatedRoundQueries.filter(roundQuery => existRoundNumbers.includes(roundQuery.round));

        currentRoundQueries = currentRoundQueries
            .filter(existRoundQuery => updatedRoundQueries.some(
                updatedRoundQuery => updatedRoundQuery.round === existRoundQuery.round))
            .concat(addedRoundQueries);

        updatedRoundQueries.forEach(roundQuery => {
            const foundRoundQuery = currentRoundQueries.find(rq => rq.round === roundQuery.round);
            foundRoundQuery.queries = roundQuery.queries;
        });

        return currentRoundQueries.sort(rd => rd.round);
    }

    public addQuery(query: IQuery, roundComments: IRoundQueries[], currentRound: number): void {
        this.initQueryReviewRoundQueryThreads([query], currentRound);
        const commentRoundQuery = roundComments.find(rc => rc.round === currentRound);
        if (!!commentRoundQuery) {
            commentRoundQuery.queries.push(query);
        } else {
            roundComments.push(this.createRoundQueries(currentRound, [query]));
        }
    }

    public getNotEmptyQueries(queries: IQuery[]): IQuery[] {
        return queries.filter(query =>
            query.queryThreads.length > 1
            || query.queryThreads.every(reply =>
                    !!reply.text
                    || !!reply.extendedText
                    || !!reply.isAcknowledged
                    || !!reply.isClosingQueryThread));
    }

    public getEmptyQueries(queries: IQuery[]): IQuery[] {
        const notEmptyQueries = this.getNotEmptyQueries(queries);
        return queries.filter(query => notEmptyQueries.every(notEmptyQuery => notEmptyQuery !== query));
    }

    private addRoundQueries(
        roundQueriesResult: IRoundQueries[],
        queries: IQuery[],
        round: number): void {
        if (!!queries.length) {
            roundQueriesResult.push(this.createRoundQueries(round, queries));
        }
    }

    private createRoundQueries(
        round: number,
        queries: IQuery[]): IRoundQueries {
        return {
            round: round,
            queries: queries
        };
    }
}
