import { ActivatedRoute } from '@angular/router';
import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { FormInfo } from 'src/app/models/form-info.model';
import { MaSpinnerService } from 'src/app/shared/ma-spinner.service';
import { WebBundleStateService } from 'src/app/services/web-bundle-state.service';

const emptyWebBundleUrl = "about:blank";

@Component({
  selector: 'viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.scss']
})
export class ViewerComponent implements OnInit {
  @ViewChild("formView") formView: ElementRef;
  private formInfo: FormInfo;

  get webBundleUrl() {
    return this.formInfo ? this.formInfo.webBundleUrl : emptyWebBundleUrl;
  }

  get formHeight() {
    return this.webBundleState.webBundle.formHeight;
  }

  private get isWebBundleLoaded() {
    return this.formView
      && this.formView.nativeElement
      && this.formView.nativeElement.getAttribute("src") === this.webBundleUrl;
  }

  constructor(
    private spinnerSvc: MaSpinnerService,
    private route: ActivatedRoute,
    private webBundleState: WebBundleStateService) { }

  ngOnInit() {
    this.route.data.pipe(first()).subscribe(data => this.formInfo = data["formInfo"]);
  }

  onWebBundleLoaded() {
    if (this.isWebBundleLoaded) {
      this.webBundleState.setLoadedState(true);
      this.webBundleState.setFormToShow(this.formInfo);
      this.hideSpinner();
    }
  }

  onError() {
    this.hideSpinner()
  }

  private hideSpinner() {
    if (this.spinnerSvc.current) {
      this.spinnerSvc.current.hide();
    }
  }
}
