import { Component, HostListener, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { SubSink } from 'subsink/dist/subsink';
import { WebBundle } from 'src/app/models/web-bundle-commands/web-bundle';
import { WebBundleStateService } from 'src/app/services/web-bundle-state.service';
import { MaSpinnerService } from 'src/app/shared/ma-spinner.service';

@Component({
  selector: 'form-viewer',
  templateUrl: './form-viewer.component.html',
  styleUrls: ['./form-viewer.component.scss']
})
export class FormViewerComponent implements OnInit, OnDestroy {
  private webBundle: WebBundle;
  private subscr = new SubSink();

  @HostListener('window:resize')
  onWindowResize() {
    this.needRescale();
  }

  constructor(private elementRef: ElementRef, wbBundleState: WebBundleStateService, private spinnerService: MaSpinnerService) {
    this.webBundle = wbBundleState.webBundle;
  }

  ngOnInit() {
    this.subscr.sink = this.webBundle.needRescale$.subscribe(_ => this.needRescale());
  }

  private needRescale() {
    const mainFrameWidth = this.elementRef.nativeElement.querySelector('.viewer-holder').getBoundingClientRect().width;
    this.webBundle.scaleToWidth(mainFrameWidth);
  }

  ngOnDestroy(): void {
    while (this.spinnerService.current) {
      this.spinnerService.current.hide();
    }

    this.subscr.unsubscribe();
  }
}
