import { Injectable } from '@angular/core';
import { FormQueryType, IQuery, IQueryThread, QueryType } from '../models/query.interface';
import { IToken } from '../models/token.interface';
import { DateTimeService } from '../shared/date-time.service';
import { GuidGenerator } from './guid-generator';

@Injectable({ providedIn: 'root' })
export class QueryCreator {

    constructor(private dateTimeService: DateTimeService) {
    }

    public createQuery(
        formTemplateItemId: string,
        formQueryName: string,
        text: string,
        token: IToken,
        round: number,
        isAuto: boolean): IQuery {
        const queryId: string = GuidGenerator.newGuid();
        return {
            queryId: queryId,
            formQueryName: formQueryName,
            queryCategoryId: null,
            updateDate: null,
            closeDate: null,
            creationDate: this.dateTimeService.getServerCurrentDateUtc(),
            addressed: null,
            isAuto: isAuto,
            queryTypeId: QueryType.ExternalReview,
            closedBy: null,
            closedById: null,
            closureTypeId: null,
            formTemplateItemId: formTemplateItemId,
            roundNumber: round,
            queryThreads: [this.createQueryThread(text, queryId, token, round, isAuto)],
            reviewRoundQueryThreads: [],
            formQueryTypeId: FormQueryType.RaterComment,
            formDiscrepancyTypeId: null,
            closedByFirstName: null,
            closedByLastName: null,
            isResolved: false,
            isModified: false,
            queryIndicators: [],
          };
    }

    public createQueryThread(
        text: string,
        queryId: string,
        token: IToken,
        round: number,
        isAuto: boolean): IQueryThread {
        return {
            personFirstName: token.firstname,
            personId: token.personId,
            personLastName: token.lastname,
            roundNumber: round,
            edited: false,
            extendedText: text,
            manualText: '',
            autoText: '',
            text: text,
            timestamp: this.dateTimeService.getServerCurrentDateUtc(),
            queryId: queryId,
            initial: true,
            queryThreadId: null,
            isClosingQueryThread: false,
            isEditMode: false,
            isAuto: isAuto,
            isPersonReviewer: false,
            isNeedToUpdate: false,
            isDeleted: false,
            isAcknowledged: false
        };
    }
}