import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy  {
  public visibleHeaderActions: boolean = true;
  public isViewerLoaded: boolean = false;

  private destroy$: Subject<void> = new Subject();
  private isViewerLoadedTimeoutId: number;
  constructor(private router: Router) {}

  get isVScrolled() {
    const viewer = this.viewerElement;
    if (!!viewer) {
        const clientHeight = viewer.clientHeight;
        const scrollHeight = viewer.scrollHeight;
        return clientHeight < scrollHeight;
    }
    return false;
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationStart),
        takeUntil(this.destroy$)
      )
      .subscribe(e => {
        this.isViewerLoaded = false;
      });

    this.router.events
        .pipe(
          filter(event => event instanceof NavigationEnd),
          takeUntil(this.destroy$))
        .subscribe(event => {
          if (!!this.isViewerLoadedTimeoutId) {
            clearTimeout(this.isViewerLoadedTimeoutId);
          }
          this.isViewerLoadedTimeoutId = setTimeout(() => {
            this.isViewerLoaded = !!this.viewerElement;
          });
          this.visibleHeaderActions = event instanceof NavigationEnd && event.url !== '/about';
        });
  }

  ngOnDestroy(): void {
    if (!!this.isViewerLoadedTimeoutId) {
      clearTimeout(this.isViewerLoadedTimeoutId);
    }

    this.destroy$.next();
    this.destroy$.complete();
  }

  private get viewerElement(): Element {
    return document.querySelector('viewer');
  }
}
