import { Injectable } from '@angular/core';
import { IFormQueryStateService } from 'src/app/interfaces/form-query-state/form-query-state-service.interface';
import { IViewFormQueryStateService } from 'src/app/interfaces/form-query-state/view-query-state-service.interface';
import { DateTimeService } from 'src/app/shared/date-time.service';
import { DiscrepancyType, FormQueryType, IQuery } from '../query.interface';
import { CommentFormQueryStateService } from './comment-form-query-state-service';
import { CommentViewFormQueryStateService } from './comment-view-form-query-state-service';
import { EsFormQueryStateService } from './es-form-query-state-service';
import { EsViewFormQueryStateService } from './es-view-form-query-state-service';
import { FsFormQueryStateService } from './fs-form-query-state-service';
import { FsViewFormQueryStateService } from './fs-view-form-query-state-service';
import { PtFormQueryStateService } from './pt-form-query-state-service';
import { PtViewFormQueryStateService } from './pt-view-form-query-state-service';
import { ReviewerCommentFormQueryStateService } from './reviewer-comment-form-query-state-service';
import { ReviewerCommentViewFormQueryStateService } from './reviewer-comment-view-form-query-state-service';
import { SsFormQueryStateService } from './ss-form-query-state-service';
import { SsViewFormQueryStateService } from './ss-view-form-query-state.service';
import { XcFormQueryStateService } from './xc-form-query-state-service';
import { XcViewFormQueryStateService } from './xc-view-form-query-state-service';

@Injectable({ providedIn: "root" })
export class FormQueryStateServiceFactory {
    private static discrapencyServiceCreators = new Map<string, (q: IQuery, dts: DateTimeService, crn: number) => IFormQueryStateService>([
        [DiscrepancyType.CrossCheck, (q: IQuery, dts: DateTimeService, crn: number) => new XcFormQueryStateService(q, dts, crn)],
        [DiscrepancyType.EdssStep, (q: IQuery, dts: DateTimeService, crn: number) => new EsFormQueryStateService(q, dts, crn)],
        [DiscrepancyType.FsScore, (q: IQuery, dts: DateTimeService, crn: number) => new FsFormQueryStateService(q, dts, crn)],
        [DiscrepancyType.SubScores, (q: IQuery, dts: DateTimeService, crn: number) => new SsFormQueryStateService(q, dts, crn)],
        [DiscrepancyType.PtMarkers, (q: IQuery, dts: DateTimeService, crn: number) => new PtFormQueryStateService(q, dts, crn)],
    ]);

    private static viewServiceCreators = new Map<string, (s: IFormQueryStateService) => IViewFormQueryStateService>([
        [CommentFormQueryStateService.name, (s: IFormQueryStateService) => new CommentViewFormQueryStateService(s)],
        [XcFormQueryStateService.name, (s: IFormQueryStateService) => new XcViewFormQueryStateService(s)],
        [EsFormQueryStateService.name, (s: IFormQueryStateService) => new EsViewFormQueryStateService(s)],
        [FsFormQueryStateService.name, (s: IFormQueryStateService) => new FsViewFormQueryStateService(s)],
        [SsFormQueryStateService.name, (s: IFormQueryStateService) => new SsViewFormQueryStateService(s)],
        [PtFormQueryStateService.name, (s: IFormQueryStateService) => new PtViewFormQueryStateService(s)],
        [ReviewerCommentFormQueryStateService.name, (s: IFormQueryStateService) => new ReviewerCommentViewFormQueryStateService(s)]
    ]);


    constructor(private dateTimeService: DateTimeService) {
    }

    public static get supportedDiscrapencyTypes(): string[] {
        return Array.from(FormQueryStateServiceFactory.discrapencyServiceCreators.keys());
    }

    getQueryStateService(query: IQuery, currentRoundNumber: number): IFormQueryStateService {
        if (query.formQueryTypeId === FormQueryType.RaterComment) {
            return new CommentFormQueryStateService(query, this.dateTimeService, currentRoundNumber);
        }

        if (query.formQueryTypeId === FormQueryType.ReviewerComment) {
            return new ReviewerCommentFormQueryStateService(query, this.dateTimeService, currentRoundNumber);
        }

        if (!FormQueryStateServiceFactory.discrapencyServiceCreators.has(query.formDiscrepancyTypeId)) {
            throw new TypeError(`Not supported discrepancy type: ${query.formDiscrepancyTypeId}`);
        }

        return FormQueryStateServiceFactory
            .discrapencyServiceCreators
            .get(query.formDiscrepancyTypeId)(query, this.dateTimeService, currentRoundNumber);
    }

    getViewQueryStateService(formQueryStateService: IFormQueryStateService) {
        const serviceType: string = formQueryStateService.constructor.name;

        if (!FormQueryStateServiceFactory.viewServiceCreators.has(serviceType)) {
            throw new TypeError(`Not supported service type: ${serviceType}`);
        }

        return FormQueryStateServiceFactory
            .viewServiceCreators
            .get(serviceType)(formQueryStateService);
    }
}