import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ConfirmationSettings } from './confirmation-settings';

@Component({
    selector: 'app-confirmation',
    templateUrl: './confirmation.component.html',
    styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {
    @Input() inputText: string;
    private activeText: string;
    customYesClickFn: () => void = null;

    get text() {
        return this.activeText;
    }

    constructor(public dialogRef: MatDialogRef<ConfirmationComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public injectedSettings: ConfirmationSettings) {
    }

    ngOnInit(): void {
        this.activeText = this.inputText || this.injectedSettings.text;
    }

}
