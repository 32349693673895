import { EventEmitter } from '@angular/core';
import { IFormQueryStateService } from 'src/app/interfaces/form-query-state/form-query-state-service.interface';
import { IQuery, IQueryReviewRound, IQueryThread, QueryClosureType } from '../query.interface';
import { FormScoreValue } from '../form-score-value.model';
import { IToken } from '../token.interface';
import { DateTimeService } from 'src/app/shared/date-time.service';
import { GuidGenerator } from '../../services/guid-generator';

export abstract class BaseFormQueryStateService implements IFormQueryStateService {
    constructor(public query: IQuery, protected dateTimeService: DateTimeService, protected currentRoundNumber: number) {
        if (!this.query.reviewRoundQueryThreads || !this.query.reviewRoundQueryThreads.length) {
            this.groupQueryThreadsByRound();
        }
    }

    public canBeClosed: boolean = true;
    public queryStateUpdated: EventEmitter<IQuery> = new EventEmitter<IQuery>();
    public abstract get queryType(): string;

    public get reviewRoundQueryThreads(): IQueryReviewRound[] {
        return this.query.reviewRoundQueryThreads;
    }

    getReviewRoundQueryThread(roundNumber: number): IQueryReviewRound {
        return this.reviewRoundQueryThreads.find(q => q.round === roundNumber);
    }

    isResolved(queryReviewRound: IQueryReviewRound) {
        const findElement = this.getReviewRoundQueryThread(queryReviewRound.round);
        const reviewRoundIndex = this.reviewRoundQueryThreads.indexOf(findElement);

        return (this.reviewRoundQueryThreads.length - 1 > reviewRoundIndex)
            || (this.reviewRoundQueryThreads.length - 1 === reviewRoundIndex && this.query.isResolved);
    }

    isClosed(queryReviewRound: IQueryReviewRound) {
        const findElement = this.getReviewRoundQueryThread(queryReviewRound.round);
        return findElement.replies.some(qt => qt.isClosingQueryThread);
    }

    public isQueryThreadProcessed(queryThread: IQueryThread): boolean {
        return !!queryThread.queryThreadId;
    }

    public deleteQueryThread(queryThread: IQueryThread): void {
        this.deleteQueryThreadFromReview(queryThread);
        const reviewQueryThread = this.getReviewRoundQueryThread(queryThread.roundNumber);
        this.query.isResolved = reviewQueryThread.replies.some(qt => qt.isAcknowledged);

        this.queryStateUpdated.emit(this.query);
    }

    public saveQueryThreadChanges(
        token: IToken,
        queryThread: IQueryThread,
        text: string,
        isAcknowledged: boolean): void {
        queryThread.isAcknowledged = isAcknowledged;
        queryThread.extendedText = text;
        queryThread.text = text;
        queryThread.edited = true;
        queryThread.isNeedToUpdate = true;
        this.queryStateUpdated.emit(this.query);
    }

    public addNewQueryThread(token: IToken, round: number, text: string, isAcknowledged: boolean): IQueryThread {
        const newThread: IQueryThread = this.getNewQueryThread(token, round, text, isAcknowledged, false);
        const reviewQueryThread = this.addQueryThreadToReview(newThread);

        this.query.isResolved = !!reviewQueryThread.replies.length;

        this.queryStateUpdated.emit(this.query);
        return newThread;
    }

    protected addQueryThreadToReview(queryThread: IQueryThread): IQueryReviewRound {
        let reviewQueryThread = this.getReviewRoundQueryThread(queryThread.roundNumber);

        if (!reviewQueryThread) {
            reviewQueryThread = {
                round: queryThread.roundNumber,
                root: queryThread,
                replies: [],
                queryId: this.query.queryId
            };

            this.query.reviewRoundQueryThreads.push(reviewQueryThread);
        }

        this.query.queryThreads.push(queryThread);
        reviewQueryThread.replies.push(queryThread);

        return reviewQueryThread;
    }

    protected deleteQueryThreadFromReview(queryThread: IQueryThread): void {
        const reviewQueryThread = this.getReviewRoundQueryThread(queryThread.roundNumber);

        queryThread.isDeleted = true;
        reviewQueryThread.replies = reviewQueryThread.replies.filter(qt => qt !== queryThread);
    }

    protected groupQueryThreadsByRound(): void {
        const map =  new Map<number, IQueryThread[]>();
        this.query.queryThreads
            .filter(qt => !qt.isDeleted)
            .sort((a, b) => a.roundNumber - b.roundNumber)
            .forEach((item) => {
                const key = item.roundNumber;
                if (map.has(key)) {
                    map.get(key).push(item);
                } else {
                    map.set(key, [item]);
                }
            });

        Array.from(map.keys()).forEach(roundNumber => map.set(
            roundNumber,
            map.get(roundNumber).sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime())));

        this.query.reviewRoundQueryThreads = [];
        map.forEach((value, key) => this.query.reviewRoundQueryThreads.push({
            round: key,
            root: value[0],
            replies: value.filter((queryThread, index) => index >= 1),
            queryId: this.query.queryId
        }));
    }

    public onFormScoreChanged(
        reviewerScoreValue: string,
        raterScoreValue: string,
        scoreName: string,
        formScoreValue: FormScoreValue,
        token: IToken,
        round: number): void {}

    public onFormScoreLoaded(
        reviewerScoreValue: string,
        raterScoreValue: string,
        scoreName: string,
        formScoreValue: FormScoreValue,
        token: IToken,
        round: number): void {}

    protected getNewQueryThread(
        token: IToken,
        round: number,
        text: string,
        isAcknowledged: boolean,
        isAuto: boolean): IQueryThread {
        return {
            personFirstName: token.firstname,
            personId: token.personId,
            personLastName: token.lastname,
            roundNumber: round,
            edited: false,
            extendedText: text,
            manualText: '',
            autoText: '',
            text: text,
            timestamp: this.dateTimeService.getServerCurrentDateUtc(),
            queryId: this.query.queryId,
            initial: false,
            queryThreadId: GuidGenerator.newGuid(),
            isClosingQueryThread: false,
            isEditMode: false,
            isAuto: isAuto,
            isPersonReviewer: false,
            isNeedToUpdate: true,
            isDeleted: false,
            isAcknowledged: isAcknowledged
        };
    }
}