import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

function environmentSettings() {
  return environmentsSettings[getBaseUrl()];;
}

const providers = [
  { provide: 'ENV_SETTINGS', useFactory: environmentSettings, deps: [] }
];

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic(providers).bootstrapModule(AppModule)
  .catch(err => console.error(err));

const environmentsSettings = {
  "http://localhost:9006/": {
    authorizationUri: "http://localhost:9007/",
    raterApi: "http://localhost:9006/raterapi/"
  },
  "https://siteportal2at.medavante.net/": {
    authorizationUri: "https://charon3at.medavante.net/",
    raterApi: "https://siteportal2at.medavante.net/raterapi/"
  },
  "https://siteportal2dev.medavante.net/": {
    authorizationUri: "https://charon3dev.medavante.net/",
    raterApi: "https://siteportal2dev.medavante.net/raterapi/"
  },
  "https://siteportal2forms.medavante.net/": {
    authorizationUri: "https://charon3forms.medavante.net/",
    raterApi: "https://siteportal2forms.medavante.net/raterapi/"
  },
  "https://siteportal2ft.medavante.net/": {
    authorizationUri: "https://charon3ft.medavante.net/",
    raterApi: "https://siteportal2ft.medavante.net/raterapi/"
  },
  "https://siteportal2pft.medavante.net/": {
    authorizationUri: "https://charon3pft.medavante.net/",
    raterApi: "https://siteportal2pft.medavante.net/raterapi/"
  },
  "https://siteportal2.medavante.net/": {
    authorizationUri: "https://charon3.medavante.net/",
    raterApi: "https://siteportal2.medavante.net/raterapi/"
  },
  "https://siteportal2qa.medavante.net/": {
    authorizationUri: "https://charon3qa.medavante.net/",
    raterApi: "https://siteportal2qa.medavante.net/raterapi/"
  },
  "https://siteportal2qa2.medavante.net/": {
    authorizationUri: "https://charon3qa2.medavante.net/",
    raterApi: "https://siteportal2qa2.medavante.net/raterapi/"
  },
  "https://siteportal2qa3.medavante.net/": {
    authorizationUri: "https://charon3qa3.medavante.net/",
    raterApi: "https://siteportal2qa3.medavante.net/raterapi/"
  },
  "https://siteportal2sandbox.medavante.net/": {
    authorizationUri: "https://charon3sandbox.medavante.net/",
    raterApi: "https://siteportal2sandbox.medavante.net/raterapi/"
  },
  "https://siteportal2stg.medavante.net/": {
    authorizationUri: "https://charon3stg.medavante.net/",
    raterApi: "https://siteportal2stg.medavante.net/raterapi/"
  },
  "https://siteportal2trn.medavante.net/": {
    authorizationUri: "https://charon3trn.medavante.net/",
    raterApi: "https://siteportal2trn.medavante.net/raterapi/"
  },
  "https://siteportal2test.medavante.net/": {
    authorizationUri: "https://charon3test.medavante.net/",
    raterApi: "https://siteportal2test.medavante.net/raterapi/"
  },
  "https://siteportal2test2.medavante.net/": {
    authorizationUri: "https://charon3test2.medavante.net/",
    raterApi: "https://siteportal2test2.medavante.net/raterapi/"
  },
}