import { Injectable } from '@angular/core';

import { FormQueryStateServiceFactory } from 'src/app/models/form-query-state/form-query-state-service-factory'
import { QueryMarker } from '../models/query-marker';
import { FormQueryType, IQuery, IQueryIndicator } from '../models/query.interface';
import { QueryOrderService } from './query-order.service';

class MarkerSource {
    query: IQuery;
    isIndicator: boolean;
}

class MarkerDetails {
    cssClassName: string;
    notResolvedIcon: string;
    text: string;
    formDiscrepancyTypeId: string;
    queries: IQuery[];
    isIndicator: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class QueryMarkerService {
    constructor(
        private queryServiceFactory: FormQueryStateServiceFactory,
        private queryOrderService: QueryOrderService) { }

    public get supportedQueryMarkerTypesCount(): number {
        // The queries with Rater comment and Reviewer manual comment types has a same marker.
        return this.queryOrderService.supportedQueryTypesCount - 1;
    }

    public getMarkers(queries: IQuery[], roundNumber: number, indicators?: IQueryIndicator[]): QueryMarker[] {
        const markerQueries = [];

        if (!queries.length && !indicators?.length) {
            return markerQueries;
        }

        const queryMarkerSourceList = this.getQueryMarkerSourceList(queries);
        const indicatorMarkerSourceList = this.getIndicatorMarkerSourceList(queries, indicators);
        const markerSourceList = queryMarkerSourceList.concat(indicatorMarkerSourceList);

        const markerDetails = this.getMarkerQueryDetails(markerSourceList, roundNumber);
        return markerDetails.map(details => this.mapMarkerQueryDetails(details));
    }

    private getQueryMarkerSourceList(queries: IQuery[]): MarkerSource[] {
        return queries.map(query => ({
            query: query,
            isIndicator: false
        }));
    }

    private getIndicatorMarkerSourceList(queries: IQuery[], indicators: IQueryIndicator[]): MarkerSource[] {
        if (!indicators || !indicators.length) {
            return [];
        }

        return indicators
            .filter(indicator =>
                queries.every(q => q.formDiscrepancyTypeId !== indicator.sourceQuery.formDiscrepancyTypeId))
            .map(indicator => ({
                query: indicator.sourceQuery,
                isIndicator: true
            }));
    }

    private getMarkerQueryDetails(markerSourceList: MarkerSource[], roundNumber: number): MarkerDetails[] {
        const result: MarkerDetails[] = [];

        markerSourceList.forEach(queryItem => {
            const stateService = this.queryServiceFactory.getQueryStateService(queryItem.query, roundNumber);
            const viewService = this.queryServiceFactory.getViewQueryStateService(stateService);

            const markerCssClassName = viewService.markerElementCssClassName;
            const searchMarkerPredicate = !!queryItem.query.formDiscrepancyTypeId
                ? (marker: MarkerDetails) => marker.formDiscrepancyTypeId === queryItem.query.formDiscrepancyTypeId
                : (marker: MarkerDetails) => marker.cssClassName === markerCssClassName;

            const foundMarker = result.find(searchMarkerPredicate);

            !!foundMarker
                ? foundMarker.queries.push(queryItem.query)
                : result.push({
                    cssClassName: markerCssClassName,
                    notResolvedIcon: viewService.notResolvedQueryCssClassName,
                    text: viewService.markerText,
                    formDiscrepancyTypeId: queryItem.query.formDiscrepancyTypeId,
                    queries: [queryItem.query],
                    isIndicator: queryItem.isIndicator
                });
        });

        return result;
    }

    private mapMarkerQueryDetails(markerDetails: MarkerDetails): QueryMarker {
        const isResolved = markerDetails.queries.every(query => query.isResolved);
        return {
            cssClassName: isResolved
                ?  markerDetails.cssClassName
                : `${markerDetails.cssClassName} ${markerDetails.notResolvedIcon}`,
            text: markerDetails.text,
            isIndicator: markerDetails.isIndicator,
            isResolved: isResolved,
            orderIndex: this.getMarkerOrderIndex(markerDetails),
        };
    }

    private getMarkerOrderIndex(markerDetails: MarkerDetails): number {
        const query = markerDetails.queries[0];
        let orderIndex = this.queryOrderService.getQueryOrder(query);

        //  Shift the order index of marker because the queries
        //  with Rater comment and Reviewer manual comment types has a same marker index.
        //  So the rater comment and manual reviewer comment markers will have the same order index.
        if (query.formQueryTypeId === FormQueryType.ReviewerComment) {
            orderIndex -= 1;
        }

        return orderIndex;
    }
}
